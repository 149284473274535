<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link :to="{ name: 'Dashboards' }">
          <sidenav-collapse
            nav-text="Dashboards"
            :collapse="false"
            :aria-controls="''"
          >
            <template #icon>
              <i style="." class="fa fa-chart-pie text-sm opacity-10"></i>
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="applicationsExamples"
          nav-text="Personal"
          :class="getRoute() === 'applications' ? 'active' : ''"
        >
          <template #icon>
            <i
              style="."
              class="fa fa-users text-secondary text-sm opacity-10"
            ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->

              <sidenav-item
                :to="{ name: 'Colaboradores' }"
                mini-icon="C"
                text="Colaboradores"
              />
              <sidenav-item
                :to="{ name: 'Departamentos' }"
                mini-icon="D"
                text="Departamentos"
              />
              <sidenav-item
                :to="{ name: 'Puestos' }"
                mini-icon="P"
                text="Puestos"
              />
              <sidenav-item
                :to="{ name: 'Gafetes' }"
                mini-icon="G"
                text="Gafetes"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Encuestas' }">
          <sidenav-collapse
            nav-text="Encuestas"
            :collapse="false"
            :aria-controls="''"
          >
            <template #icon>
              <i
                style="."
                class="fa fa-file-signature text-secondary text-sm opacity-10"
              ></i>
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Sucursales' }">
          <sidenav-collapse
            nav-text="Sucursales"
            :collapse="false"
            :aria-controls="''"
          >
            <template #icon>
              <i style="." class="fa fa-store text-sm opacity-10"></i>
            </template>
          </sidenav-collapse>
        </router-link>
      </li>
    </ul>
  </div>
  <div class="mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: '¿Necesitas ayuda?',
        description: '',
        links: [
          {
            label: 'Contáctanos',
            route:
              'https://www.creative-tim.com/product/vue-argon-dashboard-pro?ref=vadp',
            color: 'success',
          },
        ],
      }"
    />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCard,
  },
  data() {
    return {
      /*  menu: 'Dashboard', */
      selectedOption: "option1",
    };
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    updateMenu(selectedMenu) {
      this.menu = selectedMenu;
      this.$router.push(`/${selectedMenu.toLowerCase()}`);
    },
  },
};
</script>


<style lang="css" scoped>
</style>
