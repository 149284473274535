<!-- @format -->

<template>
  <div>
    <ModalAdv
      v-if="MostrarModal"
      titulo="Filtrar"
      ayuda="Introduce el texto por el cual deseas filtrar los datos."
      @close="closeModal"
    >
      <MazInput v-model="localBaseGetsListado.Filtro" label="Filtro" class="mb-3">
        <template #left-icon>
          <i class="fas fa-search"></i>
        </template>
      </MazInput>
      <MazCheckbox v-model="localBaseGetsListado.Borrados" class="mb-3"> Mostrar solo borrados </MazCheckbox>
      <div class="d-grid gap-2">
        <MazBtn class="btn btn-success" @click="OnFiltrar">Filtrar</MazBtn>
      </div>
    </ModalAdv>
  </div>
</template>

<script>
import ModalAdv from "../ModalAdv.vue";

export default {
  components: {
    ModalAdv,
  },
  props: {
    mostrar: {
      type: Boolean,
      default: true,
    },
    baseGetsListado: {
      type: Object,
      default: () => {
        return {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 10,
          Filtro: "",
        };
      },
    },
  },
  emits: ["update:mostrar", "update:base-gets-listado"],
  data() {
    return {
      MostrarModal: this.mostrar,
      localBaseGetsListado: JSON.parse(JSON.stringify(this.baseGetsListado)),
    };
  },
  methods: {
    openModal() {
      this.MostrarModal = true;
    },
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },
    OnFiltrar() {
      this.localBaseGetsListado.PaginaNo = 0;
      this.$emit("update:base-gets-listado", this.localBaseGetsListado);
      this.closeModal();
    },
  },
};
</script>
