<template>
  <div>
    <div class="row">
      <div class="col">
        <div v-if="!MuestraEditUbi">
          <h5>Ubicación de envío</h5>
          <!-- Aqui va un listado de ubicaciones -->
          <CardUbicaciones @EditarUbi="Editar"/>
        </div>
        <div v-else>
          Aqui editamos las ubicaciones
          <h4>Ubicación de envíod</h4>
        </div>
      </div>
      <div class="col">
        <div v-if="!MuestraEditUbi">
          <EditarUbicacion/>
        </div>
        <div v-else></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from "vue";
import CardUbicaciones from "@/Vistas/Gafetes/Components/CardUbicaciones.vue";
// import Servicio from '@/api-services/sucursales.service';
import EditarUbicacion from "./EditarUbicacion.vue";

const props = defineProps({
  registro: {
    type: Object,
    required: true,
  },
  Datos: {
    type: Object,
    default: () => ({ etiqueta: "" }),
  },
  fechaSolicitud: {
    type: String,
    default: "",
  },
  fechayHoraEntrega: {
    type: String,
    default: "",
  },
  recibio: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["nextStep"]);

const showFront = ref(true);
const showBack = ref(false);
const MuestraEditUbi = ref(false);
const FotoURLFront = "@/assets/img/logosya/Gafetes-22.png";
const FotoURLBack = "@/assets/img/logosya/Gafetes-21.png";
const NombreColaborador = ref("Nombre colaborador");
const Puesto = ref("Puesto");

const Editar = () => {
  MuestraEditUbi.value = true;
};
</script>

<style lang="scss" scoped>
</style>