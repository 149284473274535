<template>
  <div>
    <div class="card p-3 mx-3">
      <!-- <h1>Datos personales del colaborador</h1> -->
      <div class="row">
        <div class="col-lg-3 col-12">
          <div
            class="card"
            style="
              width: 100%;
              height: 100%;
              background: rgb(45, 206, 137);
              background: linear-gradient(
                0deg,
                rgba(45, 206, 137, 1) 0%,
                rgba(45, 206, 204, 1) 100%
              );
              display: flex;
              align-items: center;
            "
          >
            <div class="containerdos mt-5">
              <div class="outer">
                <div class="inner">
                  <input
                    class="inputfile"
                    type="file"
                    name="pic"
                    accept="image/*"
                  />
                  <label
                    ><i style="color: #2dcec6" class="fas fa-pencil-alt"></i
                  ></label>
                </div>
              </div>
            </div>
            <p class="text-center">{{ info }}</p>
            <p class="text-center">{{ colaborador.puesto }}</p>
            <p class="text-center">{{ colaborador.correo }}</p>
            <p class="text-center">{{ colaborador.numeroPersonal }}</p>
          </div>
        </div>
        <div class="col-lg-9 col-12 col-sm-12 p-0 pl-3">
          <MenuEdicion class="mx-3 mt-0 mb-2" />
          <div
            data-bs-spy="scroll"
            data-bs-target="#navbar-personal-edicion"
            class="pe-2"
            style="height: 80vh; overflow-y: auto; overflow-x: hidden"
          >
            <Empresa id="personal" class="mb-3" />
            <AccesosPlataforma id="datos-acceso" class="mb-3" />
            <DatosPersonalesAdmin id="domicilio" class="mb-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccesosPlataforma from "./Componentes/PersonalAdmin/AccesosPlataforma.vue";
import Empresa from "./Componentes/PersonalAdmin/Empresa.vue";
import DatosPersonalesAdmin from "./Componentes/PersonalAdmin/DatosPersonalesAdmin.vue";
import Servicio from "@/api-services/colaboradores.service";

export default {
  name: "PerfilAdmin",
  components: {
    AccesosPlataforma,
    DatosPersonalesAdmin,
    Empresa,
  },
  data() {
    return {
      info: null,
      colaborador: {
        puesto: "",
        correo: "",
        numeroPersonal: ""
      }
    };
  },
  mounted() {
    // Your component's mounted hook goes here
    this.Actualiza();
  },
  methods: {
    // Your component's methods go here
    Actualiza() {
      console.log("Actualiza");
      Servicio.get(
        this,
        this.BaseGetsListado,
        (response) => {
          console.debug("Esta es la info", this.info);
          this.info = response.data;
          console.debug(this.info);
          this.$notify({ type: "info", title: "Listado obtenido" });

          this.colaborador.puesto = response.data.info.listado.puesto;
          this.colaborador.correo = response.data.colaborador.correo;
          this.colaborador.numeroPersonal = response.data.colaborador.numeroPersonal;
        },
        (MsgError, error) => {
          // console.debug(MsgError);
          /* this.$notify({ type: 'danger', title: MsgError }); */
        },
        () => {
          
          this.cargando = false;
        }
      );
    },
    FotoURL(Colaborador) {
      let img = Servicio.FotoURL(Colaborador);
      return img;
    },
  },
};
</script>

<style scoped>
/* Estilos para el componente de Edición */
.containerdos {
  min-width: 200px !important;
  min-height: 200px !important;
  max-width: 250px;
  max-height: 250px;
  display: block;
  margin: 0 auto;
  padding: auto;
}

.outer {
  width: 100% !important;
  height: 100% !important;

  margin: auto;
  background-color: #6eafd4;
  border-radius: 100%;
  position: relative;
}

.inner {
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 30%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.inner:hover {
  background-color: #5555ff;
}
.inputfile {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  width: 50px;
  height: 50px;
}
.inputfile + label {
  font-size: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  width: 50px;
  height: 50px;
  pointer-events: none;
  cursor: pointer;
  line-height: 50px;
  text-align: center;
}
.inputfile + label svg {
  fill: #fff;
}
</style>
