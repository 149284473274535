<!-- @format -->

<script setup>
import { onMounted } from "vue";
import setNavPills from "@/assets/js/nav-pills.js";

onMounted(() => {
  setNavPills();
});
</script>
<template>
  <div id="navbar-personal-edicion" class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item">
        <a class="nav-link active mb-0 px-0 py-1" href="#personal">Personales</a>
      </li>
      <li class="nav-item">
        <a class="nav-link active mb-0 px-0 py-1" href="#datos-acceso">Datos de acceso</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#domicilio">Domicilio</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#laborales">Laborales</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#identificacion"> Identificación </a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#guardar">Guardar</a>
      </li>
    </ul>
  </div>
</template>
