<!-- @format -->

<template>
  <Edicion :servicio="Servicio" @update:datos="(value) => (Datos = value)">
    <template #Etiqueta>
      <ListadoSelect v-model="Datos.departamentoPadreID" tipo="Departamento Padre" />
    </template>
  </Edicion>
</template>

<script>
import Servicio from "@/api-services/departamentos.service";
import Edicion from "@/Componentes/Listados/Edicion.vue";
import ListadoSelect from "./Select.vue";
export default {
  name: "DepartamentosEdicion",
  components: {
    Edicion,
    ListadoSelect,
  },
  data() {
    return {
      Datos: {},
    };
  },
  computed: {
    Servicio() {
      return Servicio;
    },
  },
};
</script>

<style lang="scss" scoped></style>
