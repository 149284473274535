<template>
  <div class="card p-3">
    <div class="row">
      <div class="col-12">
        <small>Correo electrónico</small>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="form-check">
            <input
              v-model="localCampoEmail"
              class="form-check-input"
              type="radio"
              name="RadioEmailOpcional"
              value="Opcional"
              id="flexRadioCorreoOpcional"
            />
            <label class="form-check-label" for="flexRadioCorreoOpcional">
              Opcional
            </label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check">
            <input
              v-model="localCampoEmail"
              class="form-check-input"
              type="radio"
              name="RadioEmailObligatorio"
              value="Obligatorio"
              id="flexRadioCorreoObligatorio"
            />
            <label class="form-check-label" for="flexRadioCorreoObligatorio">
              Obligatorio
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    campoEmail: {
      type: String,
      default: "",
    },
  },
  emits: ["update:campoEmail"],
  data() {
    return {
      localCampoEmail: this.campoEmail,
    };
  },
  watch: {
    localCampoEmail(Valor) {
      this.$emit("update:campoEmail", Valor);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>