<!-- @format -->

<template>
  <div class="card shadow-lg bg-light p-3">
    <div class="row">
      <div class="col">
        <h5>Identificación</h5>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p>
          Es importante que llenes correctamente estos datos, ya que será la
          forma para identificar a tus colaboradores.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <p><small>*Nombre que aparecerá en reportes</small></p>
      </div>
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <p><small>*Nombre que aparecerá en el gafete</small></p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <MazInput
          v-model="localEtiqueta"
          type="text"
          label="Etiqueta"
          @update:model-value="(value) => update('etiqueta', value)"
        />
      </div>
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <MazInput
          v-model="localSobrenombre"
          type="text"
          label="Sobrenombre"
          @update:model-value="(value) => update('sobreNombre', value)"
        />
      </div>
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <SelectEncuestas v-model="localEncuesta" :class="EstiloCamposPerfil"
          @update:model-value="(value) => update('encuestaID', value)"/>
      </div>
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <router-link class="btn-identifica" to="/solicitud" type="button"
          ><p class="text-center pt-2">Imprimir gafete</p>
        </router-link>
        <!-- <button type="button" class="btn-identifica">Imprimir gafete</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from "vue";
import SelectEncuestas from "@/Vistas/Encuestas/Select.vue";

export default {
  components: {
    SelectEncuestas,
  },
  props: {
    etiqueta: {
      type: String,
      default: "",
    },
    sobreNombre: {
      type: String,
      default: "",
    },
    encuestaID: {
      type: String,
      default: "",
    },
  },
  emits: ["update:etiqueta", "update:sobreNombre", "update:encuestaID"],
  data() {
    return {
      localEtiqueta: this.etiqueta,
      localSobrenombre: this.sobreNombre,
      localEncuesta: this.encuestaID,
      Erroneo: "",
    };
  },
  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {
    watch(
      () => this.etiqueta,
      (newVal) => {
        this.localEtiqueta = newVal;
      }
    );
    watch(
      () => this.sobreNombre,
      (newVal) => {
        this.localSobrenombre = newVal;
      }
    );
    watch(
      () => this.encuestaID,
      (newVal) => {
        this.localEncuesta = newVal;
      }
    );
  },
  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },
  },
};
</script>

<style scoped>
.custom-select {
  width: 200px;
}

.custom-select .maz-select-dropdown .maz-dropdown-menu .maz-dropdown-item {
  font-size: 14px;
  color: red;
}

.btn-identifica {
  width: 10rem;
  height: 3rem;
  border: 1px solid #2dcec6;
  border-radius: 10px;
  color: #2dcec6;
}
.btn-identifica:hover {
  width: 10rem;
  height: 3rem;
  background-color: #2cce94;
  border-radius: 10px;
  color: #ffffff;
}
</style>
