<template>
    <div class="card p-3 m-3">
        <h4 class="text-center">Solicitud de gafete</h4>
        <NewProduct/>
        <!-- <StepSolicitud/> -->
    </div>
</template>

<script>
// import StepSolicitud from '../Personal/Componentes/StepSolicitud.vue';
import NewProduct from '@/Vistas/Personal/Componentes/NewProduct.vue';
    export default {
        name: " SolicitudGafete",
        components: {
            NewProduct,
            // StepSolicitud,
        }
    }
</script>

<style lang="scss" scoped>

</style>