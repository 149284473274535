<template>
  <div
    class="card d-flex justify-content-center align-items-center pt-3 cardAgregarPregunta"
  >
    <div class="row">
      <div class="col">
        <router-link to="/">
          <p class="cardAgregarPregunta__parrafo">
            <i class="fas fa-plus"></i> Agregar pregunta
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cardAgregarPregunta {
  border: 1px dashed #2dcec6;
}
.cardAgregarPregunta__parrafo {
  color: #2dcec6;
}
</style>