<template>
  <div class="card bg-light p-3">
    <div class="row">
      <div class="col">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1Enviar"
          />
          <label class="form-check-label" for="flexRadioDefault1Enviar">
            Sucursal {Nombre de empresa}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
            { Sucursal }
        </div>
      </div>
      <div class="row">
        <div class="col">
            { Direccion }
        </div>
        <div class="col">
            { nombre } - {numero telefonico}
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col">
            <router-link :to="{}" @click="Editar">
                Editar
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardUbicaciones",
  components: {
 
  },
  props: {
 
  },
  emits: ["EditarUbi"],
  data() {
    return {
    
    };
  },

  computed: {
   
  },
  watch: {

  },
  mounted() {
 
  },
  methods: {
  Editar(){
    this.$emit("EditarUbi")
  },
  },
};
</script>

<style lang="scss" scoped>
</style>