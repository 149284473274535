<!-- @format -->

<template>
  <Edicion :servicio="Servicio" @update:datos="(value) => (Datos = value)">
    <template #Etiqueta>
      <ListadoSelect v-model="Datos.departamentoID" />
    </template>
  </Edicion>
</template>

<script>
import Servicio from "@/api-services/puestos.service";
import Edicion from "@/Componentes/Listados/Edicion.vue";
import ListadoSelect from "@/Vistas/Departamentos/Select.vue";
export default {
  name: "PuestosEdicion",
  components: {
    Edicion,
    ListadoSelect,
  },
  data() {
    return {
      Datos: {},
    };
  },
  computed: {
    Servicio() {
      return Servicio;
    },
  },
};
</script>

<style lang="scss" scoped></style>
