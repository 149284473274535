<script setup>
import { onMounted } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
  id: {
    type: String,
    default: "doughnut-chart",
  },
  title: {
    type: String,
    default: "",
  },
  height: {
    type: String,
    default: "300",
  },
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Object,
      label: String,
      data: Array,
    },
  },
});

onMounted(() => {
  let ctx = document.getElementById(props.id).getContext("2d");

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }

  new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: props.chart.labels,
      datasets: [
        {
          label: props.chart.datasets.label,
          weight: 9,
          cutout: 60,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: [
            "#34CEA0",
            "#182B4D",
            "#7C7C7C",
            "#2DCEC6",
            "#6BB056",
          ],
          data: props.chart.datasets.data,
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  });
});
</script>
<template>
  <div>
    <div class="container">
      <div class="row">
        <div
          class="col-1 text-center mt-3"
          style="
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #21c997;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <i class="fa fa-qrcode" style="color: white; font-size: 1.5rem"></i>
        </div>
        <div class="col-9 mt-3">
          <h5>Seguimiento de evaluaciones</h5>
        </div>
      </div>
      <div class="p-3">
        <div class="chart">
          <canvas
            :id="props.id"
            class="chart-canvas"
            :height="props.height"
          ></canvas>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col d-flex justify-content-end align-items-end">
            <p class="text-end">Evaluaciones</p>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end align-items-end">
            <p class="text-end">100</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
