<template>
  <div class="">
    <h4>Personaliza tus preguntas</h4>
    <p>
      En esta sección podrás adaptar preguntas con tus preferencias, para que
      tus clientes cuenten su experiencia.
    </p>
    <small>Recomendamos agregar máximo 5 preguntas para una evaluación más efectiva</small>
    <div class="form-check form-switch mb-3 mt-3">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefaultTicket"
      />
      <label class="form-check-label" for="flexSwitchCheckDefaultTicket"
        >Quiero que mis clientes capturen ticket</label
      >
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-4 mb-5">
        <MenuEncuestas v-model="Seleccion"/>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="parrafo__datos">Datos personales que le solicitarás a tu cliente</p>
      </div>
    </div>

    <div class="row p-0 m-0">
      <div class="col-5 p-0 m-0">
        <ConfidencialCliente v-model:campoNombre="localConfiCliente" />
      </div>
      <div class="col-4 p-0 m-0">
        <CorreoElectronico v-model:campoEmail="localCorreoElectronico" />
      </div>
      <div class="col-3 p-0 m-0">
        <CardFechaNac v-model:campoFecha="localCardFechaNac" />
      </div>
    </div>
    <div class="row p-0 m-0">
      <div class="co p-0 m-0l">
        <ConfiguracionPregunta v-model="localPregunta" />
      </div>
    </div>
    <div class="row p-0 m-0">
      <div class="co p-0 m-0l">
        <AgregarPregunta/>
      </div>
    </div>
  </div>
  <div
      v-if="Seleccion == 'Preguntas'"
      class="mt-4 p-2 row d-flex justify-content-between"
    >
      <!-- Inicia la importacion de componente Preguntas -->
      <!-- <LayoutPreguntas /> -->
      <!-- Termina la importacion de componente Preguntas -->
    </div>
    <div v-else class="mt-4 row d-flex justify-content-between">
      <!-- Inicia la importacion de componente Idiomas -->
      <Idiomas />
      <!-- Termina la importacion de componente Idiomas -->
    </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Quill from "quill";
import AgregarPregunta from '@/Vistas/Encuestas/Componentes/AgregarPregunta.vue';
import CardFechaNac from "./CardFechaNac.vue";
import CorreoElectronico from "./CardCorreoElectronico.vue";
import ConfidencialCliente from "./CardConfidencialCliente.vue";
import ConfiguracionPregunta from "./ConfiguracionPregunta.vue";
import Idiomas from '@/Componentes/Idiomas.vue';
import MenuEncuestas from "../../../Componentes/MenuEncuestas.vue";

export default {
  components: {
    AgregarPregunta,
    CardFechaNac,
    CorreoElectronico,
    ConfidencialCliente,
    ConfiguracionPregunta,
    Idiomas,
    MenuEncuestas,
  },
  props: {
    campoNombre: {
      type: String,
      default: "",
    },
    campoEmail: {
      type: String,
      default: "",
    },
    campoFecha: {
      type: String,
      default: "",
    },
  },

  emits: [
    "update:campoNombre",
    "update:campoEmail",
    "update:campoFecha",

    "editar",
  ],
  data() {
    return {
      Seleccion: "Preguntas",
      localConfiCliente: null,
      localCorreoElectronico: null,
      localCardFechaNac: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.parrafo__datos{
  font-size: 1rem;
  color: #2DCEC6;
}
</style>