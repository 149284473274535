<template>
  <div class="container-fluid">
    <!-- <p>Soy componente editar ubicacion</p> -->
    <h5>Editar ubicación</h5>
    <DomicilioComp class="mt-4" v-model="localdDireccion" />
    <div class="row mt-3">
      <div class="col">
        <div ref="map" style="height: 400px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import DomicilioComp from "@/Componentes/Domicilio.vue";

export default {
  name: "MapComponent",
  components: {
    DomicilioComp,
  },
  props: {
    dDireccion: {
      type: Object,
      default: () => ({
        pais: null,
        dirCalle: null,
        dirCalleNo: null,
        dirInterior: null,
        colonia: null,
        tipoAsentamiento: null,
        municipio: null,
        ciudad: null,
        estado: null,
        cp: null,
        gps: null,
      }),
    },
  },
  emits: ["update:dDireccion"],
  data() {
    return {
      localdDireccion: this.dDireccion,
    };
  },
  mounted() {
    this.loadMap();
  },
  methods: {
    update(campo, value) {
      this.$emit(`update:dDireccion`, this.localdDireccion);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },
    loadMap() {
      // Google Maps API
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyChCvp2XDGSPqEbQZZJDh28RPqy8gHgONY&callback=initMap`;
      googleMapsScript.async = true;
      googleMapsScript.defer = true;
      window.initMap = this.initMap;
      document.head.appendChild(googleMapsScript);
    },
    initMap() {
      // Coordenadas para centrar el mapa en la Ciudad de México
      const cdmxLatLng = { lat: 19.4326, lng: -99.1332 };

      // Crear mapa
      const map = new window.google.maps.Map(this.$refs.map, {
        center: cdmxLatLng,
        zoom: 12, // Zoom para mostrar la Ciudad de México
      });

      // Marcador
      const marker = new window.google.maps.Marker({
        position: cdmxLatLng,
        map,
        title: "Ciudad de México",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>