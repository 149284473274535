<!-- @format -->

<template>
  <div>
    <ListadoGeneral
      :cargando="cargando"
      :msg-error="MsgError"
      :info="info"
      titulo="Puestos"
      tipo="puesto"
      tipo-icono="fas fa-user-md"
      parrafo="El puesto es la agrupación de todas aquellas actividades que debera realizar el empleado o colaborador dentro del organigrama de la organización."
      :base-gets-listado="BaseGetsListado"
      :validar-datos="ValidarDatos"
      @actualiza="Actualiza"
      @agregar="Agregar"
      @borrar="Borrar"
      @reactivar="Reactivar"
      @update:datos-nuevo="(value) => (Datos = value)"
    >
      <template #nuevo>
        <MazInput v-model="Datos.etiqueta" label="Etiqueta" required   class="mb-campo">
          <template #left-icon>
            <i class="fas fa-tag"></i>
          </template>
          <template #right-icon> </template>
        </MazInput>
        <MazTextarea v-model="Datos.descripcion" label="Comentarios" class="mb-campo"/>
        <ListadoSelect
          v-model="Datos.departamentoID"  class="mb-campo"
          />
      </template>
      
    </ListadoGeneral>
  </div>
</template>

<script>
import ListadoGeneral from "../../Componentes/ListadoGeneral.vue";
import Servicio from "@/api-services/puestos.service";
import ListadoSelect from "@/Vistas/Departamentos/Select.vue";

export default {
  name: "PuestosListado",
  components: {
    ListadoGeneral,
    ListadoSelect
  },
  data() {
    return {
      info: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },

      Datos: {
        etiqueta: "",
        descripcion: "",
        departamentoID: "",
      },
    };
  },
  methods: {
    ValidarDatos(Datos) {
      console.log("ValidarDatos", Datos);

      return true;
    },
    Agregar(Parametros) {
      Servicio.post(
        this,
        Parametros.registro,
        (response) => {
          if (Parametros.OnAgregado != null) Parametros.OnAgregado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
      Evento de borrar registro
      @param Parametros{
        registro: registro a borrar
        OnBorrado: callback para ejecutar después de borrar
      }
    */
    Borrar(Parametros) {
      Servicio.delete(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
      Evento de Reactivar registro
      @param Parametros{
        registro: registro a borrar
        OnBorrado: callback para ejecutar después de borrar
      }
    */
    Reactivar(Parametros) {
      Servicio.patch(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },

    Actualiza(value) {
      this.BaseGetsListado = value;
      Servicio.gets(this, value, (response) => {
        this.info = response.data;
        //    console.log("info", this.info);
      });
    },
  },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>
