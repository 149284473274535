<!-- @format -->

<template>
  <Edicion :servicio="Servicio" @update:datos="(value) => (Datos = value)">
    <template #Completo>
      <LayoutPreguntas
        :campoNombre="Datos.campoNombre"
        :campoEmail="Datos.campoEmail"
        :campoFecha="Datos.campoFecha"
        @update:campoNombre="(value) => (Datos.campoNombre = value)"
        @update:campoEmail="(value) => (Datos.campoEmail = value)"
        @update:campoFecha="(value) => (Datos.campoFecha = value)"
      />
    </template>
  </Edicion>
</template>
  
<script>
import Servicio from "@/api-services/encuestas.service";
import Edicion from "@/Componentes/Listados/Edicion.vue";
// import ListadoSelect from "./Select.vue";
import LayoutPreguntas from "./Componentes/LayoutPreguntas.vue";

export default {
  name: "EncuestasEdicion",
  components: {
    Edicion,
    LayoutPreguntas,
    // ListadoSelect,
  },
  data() {
    return {
      Datos: {},
    };
  },
  computed: {
    Colaborador() {
      if (this.Datos.colaborador == null) return {};
      return this.Datos.colaborador;
    },
    Servicio() {
      return Servicio;
    },
  },
};
</script>
  
  <style lang="scss" scoped></style>
  