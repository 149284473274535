<script setup>
import { ref, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import Step1Solicitar from "../../Gafetes/Components/Step1Solicitar.vue";
import LayoutFormaEntrega from '../../Gafetes/Components/LayoutFormaEntrega.vue';
import Step5GafeteEntregado from "../../Gafetes/Components/Step5GafeteEntregado.vue";
import Step2SolicitudRecibida from "../../Gafetes/Components/Step2SolicitudRecibida.vue";
import Step3CreandoGafete from "../../Gafetes/Components/Step3CreandoGafete.vue";
import Step4GafeteEnviado from "../../Gafetes/Components/Step4GafeteEnviado.vue";
import setNavPills from "@/assets/js/nav-pills.js";

const body = document.getElementsByTagName("body")[0];
const store = useStore();

const activeClass = "js-active position-relative";
const activeStep = ref(0);
const formSteps = 3;

const nextStep = () => {
  if (activeStep.value < formSteps) {
    activeStep.value += 1;
  }
};
const prevStep = () => {
  if (activeStep.value > 0) {
    activeStep.value -= 1;
  }
};

onBeforeMount(() => {
  store.state.layout = "custom";
  store.state.showNavbar = false;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
});
onMounted(() => {
  store.state.isAbsolute = true;
  setNavPills();
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.layout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
  body.classList.remove("new-product");
});
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="mt-4 mb-3 col-12 col-lg-12 mb-sm-5">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn js-active"
                  type="button"
                  title="Forma entrega"
                  :class="activeStep >= 0 ? activeClass : ''"
                  @click="activeStep = 0"
                >
                  <i class="fas fa-file-export fa-2x"></i>
                  <br />
                  <p>Solicitar</p>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Media"
                  :class="activeStep >= 1 ? activeClass : ''"
                  @click="activeStep = 1"
                >
                  <i class="fas fa-check-circle fa-2x"></i>
                  <br />
                  <p>Solicitud Recibida</p>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Socials"
                  :class="activeStep >= 2 ? activeClass : ''"
                  @click="activeStep = 2"
                >
                  <i class="fas fa-id-card fa-2x"></i>
                  <br />
                  <p>Creando gafete</p>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Pricing"
                  :class="activeStep >= 3 ? activeClass : ''"
                  @click="activeStep = 3"
                >
                  <i class="fas fa-shipping-fast fa-2x"></i>
                  <br />
                  <p>Gafete enviado</p>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Pricing"
                  :class="activeStep >= 4 ? activeClass : ''"
                  @click="activeStep = 4"
                >
                  <i class="fas fa-box-open fa-2x"></i>
                  <br />
                  <p>Gafete entregado</p>
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-12">
              <form class="mb-8 multisteps-form__form">
                <!--single form panel-->
                <Step1Solicitar
                  :class="activeStep === 0 ? activeClass : ''"
                  @next-step="nextStep"
                />
                <!--single form panel-->
                <!-- <layout-forma-entrega
                  :class="activeStep === 0 ? activeClass : ''"
                  @next-step="nextStep"
                /> -->
                <!--single form panel-->
                <Step2SolicitudRecibida
                  :class="activeStep === 1 ? activeClass : ''"
                  @next-step="nextStep"
                  @prev-step="prevStep"
                />
                <!--single form panel-->
                <Step3CreandoGafete
                  :class="activeStep === 2 ? activeClass : ''"
                  @next-step="nextStep"
                  @prev-step="prevStep"
                />
                <!--single form panel-->
                <Step4GafeteEnviado
                  :class="activeStep === 3 ? activeClass : ''"
                  @prev-step="prevStep"
                />
                <!--single form panel-->
                <Step5GafeteEntregado
                  :class="activeStep === 4 ? activeClass : ''"
                  @next-step="nextStep"
                  @prev-step="prevStep"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
