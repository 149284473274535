<script>
import { onMounted } from "vue";
import { DataTable } from "simple-datatables";
import ArgonAvatar from "./ArgonAvatar.vue";
/* const getData = async () => {
  const response = await fetch("https://dummyjson.com/users");
  const { users } = await response.json();
  return users;
}; */
/* onMounted(async () => {
  const result = await getData();

  if (result.length > 0) {
    const dataTableBasic = new DataTable("#datatable-basic", {
      searchable: false,
      fixedHeight: true,
    });

    const tableRows = result.map((obj) => {
      const { firstName, lastName, age, gender, birthDate, bloodGroup } = obj;
      return [
        firstName,
        lastName,
        age.toString(),
        gender,
        birthDate,
        bloodGroup,
      ];
    });

    if (tableRows) {
      dataTableBasic.rows.add(tableRows);
    }
  } */
// eslint-disable-next-line no-unused-vars
/* const dataTableSearch = new DataTable("#datatable-search", {
    searchable: true,
    fixedHeight: true,
  });
}); */
export default {
  name: "DataTablas",
  componets: {
    ArgonAvatar,
  },
  data() {
    return {
      mostrar: false,
      filtro1: null,
      filtro2: null,
      filtro3: null,
      opcionesFiltro1: [
        { value: "opcion1", label: "Opción 1" },
        { value: "opcion2", label: "Opción 2" },
        { value: "opcion3", label: "Opción 3" },
      ],
      opcionesFiltro2: [
        { value: "opcionA", label: "Opción A" },
        { value: "opcionB", label: "Opción B" },
        { value: "opcionC", label: "Opción C" },
      ],
      opcionesFiltro3: [
        { value: "opcionX", label: "Enviado" },
        { value: "opcionY", label: "Entregado" },
        { value: "opcionZ", label: "Solicitud enviada" },
        { value: "opcionZ", label: "Sin solicitud" },
      ],
      registros: [
        {
          nombre: "Juan Perez",
          fechaSolicitud: "13 de febrero del 2024",
          estadoGafete: "Pendiente",
          fechaHoraEntregar: new Date("2024-03-15T10:24:00"),
          enviadoA: "Recepción",
          recibidoEn: "Recepción",
        },
        {
          nombre: "Maria Rodriguez",
          fechaSolicitud: new Date("2024-03-10"),
          estadoGafete: "Entregado",
          fechaHoraEntregar: new Date("2024-03-11 09:30"),
          enviadoA: "Oficina 3",
          recibidoEn: "Usuario",
        },
        {
          nombre: "Juan Perez",
          fechaSolicitud: new Date("2024-03-12"),
          estadoGafete: "Pendiente",
          fechaHoraEntregar: new Date("2024-03-15T10:24:00"),
          enviadoA: "Recepción",
          recibidoEn: "Recepción",
        },
        {
          nombre: "Maria Rodriguez",
          fechaSolicitud: new Date("2024-03-10"),
          estadoGafete: "Entregado",
          fechaHoraEntregar: new Date("2024-03-11 09:30"),
          enviadoA: "Oficina 3",
          recibidoEn: "Usuario",
        },
        {
          nombre: "Juan Perez",
          fechaSolicitud: new Date("2024-03-12"),
          estadoGafete: "Pendiente",
          fechaHoraEntregar: new Date("2024-03-15T10:24:00"),
          enviadoA: "Recepción",
          recibidoEn: "Recepción",
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      const partes = date.split(" ");
      const dia = partes[0];
      const mes = partes[2];
      const año = partes[4];
      return `${dia} ${mes} ${año}`;
    },
    formatDateTime(dateTime) {
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return (
        dateTime.toLocaleDateString("es-ES", options) +
        " " +
        dateTime.toLocaleTimeString("en-US", { hour12: true })
      );
    },
    mostrarModal() {
      this.mostrar = true;
    },
    cerrarModal() {
      this.mostrar = false;
    },
    aplicarFiltros() {
      console.log(
        "Filtros aplicados:",
        this.filtro1,
        this.filtro2,
        this.filtro3
      );
      this.cerrarModal();
    },
  },
};
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Dynamic Datatable</h5>
            <p class="mb-0 text-sm">
              A lightweight, extendable, dependency-free javascript HTML table
              plugin.
            </p>
          </div>
          <div class="table-responsive">
            <table id="datatable-basic" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    First Name
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Last Name
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Age
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Gender
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Birth date
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Blood Group
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header container">
            <div class="row">
              <div class="col">
                <h5 class="mb-0">Datatable Search</h5>
                <p class="mb-0 text-sm">
                  A lightweight, extendable, dependency-free javascript HTML
                  table plugin.
                </p>
              </div>
              <div class="col text-end">
                <!-- Botón con el icono de filtro -->
                <MazBtn
                  @click="mostrarModal"
                  icon="maz-icon-filter"
                  class="btn btn-success"
                  ><i class="fa fa-filter"></i
                ></MazBtn>
                <!-- Modal de filtrado con Bootstrap -->
                <div
                  class="modal"
                  :class="{ show: mostrar }"
                  tabindex="-1"
                  role="dialog"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div
                        class="modal-header"
                        style="
                          background-color: transparent !important;
                          border: none;
                        "
                      >
                        <h5 class="modal-title align-items-center">Filtro</h5>
                        <button
                          type="button"
                          class="close"
                          @click="cerrarModal"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div
                        class="modal-body"
                        style="
                          background-color: transparent !important;
                          border: none;
                        "
                      >
                        <h5>Filtro</h5>
                        <div class="form-group">
                          <!-- <MazSelect
                            v-model="filtro1"
                            label="Fecha de entrega"
                            :options="opcionesFiltro1"
                          ></MazSelect> -->
                          <MazPicker
                            class="maz"
                            style=""
                            v-model="newDateValue"
                            label="Fecha de entrega"
                          >
                            <div
                              class="flex items-center"
                              style="width: 100%; gap: 1rem"
                            >
                              <small>
                                {{ option.label }}
                              </small>
                              <!-- <hr /> -->
                            </div>
                          </MazPicker>
                        </div>
                        <div class="form-group">
                          <MazSelect
                            v-model="filtro2"
                            label="Fecha de solicitud"
                            :options="opcionesFiltro2"
                            v-slot="{ option }"
                          >
                            <div
                              class="flex items-center"
                              style="width: 100%; gap: 1rem"
                            >
                              <small>
                                {{ option.label }}
                              </small>
                              <!-- <hr /> -->
                            </div>
                          </MazSelect>
                        </div>
                        <div class="form-group">
                          <MazSelect
                            v-model="filtro3"
                            label="Estado"
                            :options="opcionesFiltro3"
                            v-slot="{ option }"
                          >
                            <div
                              class="flex items-center"
                              style="width: 40vh; gap: 1rem"
                            >
                              <small>
                                {{ option.label }}
                              </small>
                              <!-- <hr /> -->
                            </div>
                          </MazSelect>
                        </div>
                      </div>
                      <div
                        class="modal-footer"
                        style="
                          background-color: transparent !important;
                          border: none;
                        "
                      >
                        <div class="row d-flex justify-content-center">
                          <div class="col-12 align-content-center">
                            <button
                              type="button"
                              class="btn btn-primary btn-block"
                              @click="aplicarFiltros"
                            >
                              Filtrar
                            </button>
                            <!-- <button type="button" class="btn btn-secondary" @click="cerrarModal">Cerrar</button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th class="text-start">Nombre</th>
                  <th class="text-center">Fecha de solicitud</th>
                  <th class="text-center">Estado de gafete</th>
                  <th class="text-center">Fecha y hora de entrega</th>
                  <th class="text-center">Enviado a</th>
                  <th class="text-center">Recibió</th>
                </tr>
              </thead>
              <tbody>
                <!-- <td><ArgonAvatar/></td> -->
                <tr v-for="(registro, index) in registros" :key="index">
                  <!-- <td><ArgonAvatar/></td> -->
                  <td class="text-center">{{ registro.nombre }}</td>
                  <td class="text-center">{{ registro.fechaSolicitud }}</td>
                  <td class="text-center">{{ registro.estadoGafete }}</td>
                  <td class="text-center">{{ registro.fechaHoraEntregar }}</td>
                  <td class="text-center">{{ registro.enviadoA }}</td>
                  <td class="text-center">{{ registro.recibidoEn }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.modal-header,
.modal-footer {
  padding: 10px 15px;
  background-color: #f2f2f2;
}

.modal-header .close {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-body {
  padding: 10px 15px;
}

.show {
  display: block;
}

/* Ajustar el ancho de los elementos option */
.maz-select .maz-select-dropdown {
  width: 100%;
  height: 100%;
}
.maz-picker{
  color: aquamarine;
}
</style>