<template>
  <div class="card p-3">
    <div class="row">
      <div class="col">
        <small>
          Nombre *Recomendamos no solicitar para la confidencialidad del cliente
        </small>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="form-check">
            <input
              v-model="localCampoNombre"
              class="form-check-input"
              type="radio"
              name="RadioNombreNo"
              value="No"
              id="flexRadioNoSolicitar"
            />
            <label class="form-check-label" for="flexRadioNoSolicitar">
              No solicitar
            </label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check">
            <input
              v-model="localCampoNombre"
              class="form-check-input"
              type="radio"
              name="RadioNombreOpt"
              value="Opcional"
              id="flexRadioOpcional"
            />
            <label class="form-check-label" for="flexRadioOpcional">
              Opcional
            </label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check">
            <input
              v-model="localCampoNombre"
              class="form-check-input"
              type="radio"
              name="RadioNombreO"
              value="Obligatorio"
              id="flexRadioObligatorio"
            />
            <label class="form-check-label" for="flexRadioObligatorio">
              Obligatorio
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campoNombre: {
      type: String,
      default: "",
    },
  },
  emits: ["update:campoNombre"],
  data() {
    return {
      localCampoNombre: this.campoNombre,
    };
  },
  watch: {
    localCampoNombre(Valor) {
      this.$emit("update:campoNombre", Valor);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>