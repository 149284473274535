<template>
  <div>
    <div class="container">
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-1">
          <i class="fa fa-check-circle" style="color: #21c997; font-size: 3rem;"></i>
        </div>
        <!-- <div class="col text-center" style="width: 50px; height: 50px; border-radius: 90%; background-color: #21C997; display: flex; justify-content: center; align-items: center;">
          <i class="fa fa-check" style="color: white; font-size: 1rem;"></i>
        </div> -->
        <div class="col-lg-5 col-5 col-sm-12">
          <h5>&nbsp;&nbsp; Calificaciones</h5>
        </div>
        <div class="col-lg-6 col-6 col-sm-12">
          <MenuCalificaciones />
        </div>
      </div>
    </div>
    <div class="col-md-12" v-for="(question, index) in questions" :key="index">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-9 col-9 col-sm-12 m-0">
            <div class="card-body">
              <small class="card-title">{{ question.title }}</small>
              <!-- <p class="card-text">{{ question.description }}</p> -->
            </div>
          </div>
          <div class="col-lg-3 col-3 col-sm-12 m-0">
            <div class="card-footer">
              <i class="fa fa-star" @click="addStar(index)"></i>
              <span>&nbsp;{{ question.stars }}</span>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import MenuCalificaciones from "../../../views/pages/Account/components/MenuCalificaciones.vue";

export default {
  components: {
    MenuCalificaciones,
  },

  setup() {
    return {
      questions: [
        {
          title: "¿Como calificarías la calidad de nuestro servicio",
          description: "Descripción de la pregunta 1",
          stars: 3.5,
        },
        {
          title: "¿Como calificarías la limpieza y presentación de nuestras instalaciones?",
          description: "Descripción de la pregunta 2",
          stars: 4.1,
        },
        {
          title: "¿La eficiencias de (colaboradores) en sus tareas fue satisfactoria?",
          description: "Descripción de la pregunta 3",
          stars: 5.1,
        },
        {
          title: "¿La comunicación de (colaboradores) fue efectiva?",
          description: "Descripción de la pregunta 3",
          stars: 3.5,
        },
        {
          title: "¿Qué tan satisfecho/a estas con el trabajo de (colaboradores)?",
          description: "Descripción de la pregunta 3",
          stars: 4.1,
        },
      ],
    };
  },
  methods: {
    agregarEstrella(index) {
      this.questions[index].stars++;
    },
  },
};
</script>

<style lang="css" scoped>
</style>