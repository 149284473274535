<!-- @format -->

<template>
  <Modal
    v-if="MostrarModal"
    :show="MostrarModal"
    modal-classes="modal-dialog-centered"
    @close="closeModal"
  >
    <template v-slot:header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <div>
        <h6 class="card-category">{{ categoria }}</h6>
        <h5 class="card-title">{{ titulo }}</h5>
        <span class="text-muted">{{ ayuda }}</span>
      </div>
    </template>
    <template v-slot:body>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <div class="p-3">
        <slot></slot>
      </div>
    </template>
    <slot> </slot>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";

export default {
  components: {
    Modal,
  },
  props: {
    mostrar: {
      type: Boolean,
      default: true,
    },
    categoria: {
      type: String,
      default: "",
    },
    titulo: {
      type: String,
      default: "",
    },
    ayuda: {
      type: String,
      default: "",
    },
  },
  emits: ["update:mostrar"],

  data() {
    return {
      MostrarModal: this.mostrar,
    };
  },
  methods: {
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },
  },
};
</script>
