<template>
  <div class="card ms-2 ml-2 pt-3">
    <div class="container">
      <div class="row">
        <div class="col-1">
          <MazSelect
            v-model="selectedItemsPerPage"
            label="15"
            :color="color"
            :options="['5', '10', '15']"
          />
        </div>
        <div class="col-6">
          <p>Entradas por páginas</p>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Hora y fecha</th>
                <th scope="col">
                  ¿La eficiencia de Fer en sus...
                </th>
                <th scope="col">¿Hubo algún aspecto destacado</th>
                <th scope="col">¿Que tan satisfecho/a estas con...</th>
                <th scope="col">¿Que tan satisfecho/a estas con...</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">12/Febrero/2024. 1:45pm</th>
                <td><i class="fa fa-star"></i>4</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
};
</script>

<style lang="scss" scoped>
.tooltip {
  font-size: 0.875rem;
}
</style>