import Notifications from './Notifications.vue';
import { reactive } from 'vue';

const NotificationStore = {
  state: reactive([]), // here the notifications will be added
  settings: {
    overlap: false,
    verticalAlign: 'top',
    horizontalAlign: 'right',
    type: 'info',
    timeout: 5000,
    closeOnClick: true,
    showClose: true,
    order: 'reverse' // normal | reverse (When reverse, each notification will be added on top)
  },
  setOptions(options) {
    this.settings = Object.assign(this.settings, options);
  },
  removeNotification(timestamp) {
    const indexToDelete = this.state.findIndex(n => n.timestamp === timestamp);
    if (indexToDelete !== -1) {
      this.state.splice(indexToDelete, 1);
    }
  },
  addNotification(notification) {
    if (typeof notification === 'string' || notification instanceof String) {
      notification = { message: notification };
    }
    notification.timestamp = new Date();
    notification.timestamp.setMilliseconds(
      notification.timestamp.getMilliseconds() + this.state.length
    );
    notification = Object.assign({}, this.settings, notification);
    if (this.settings.order === 'reverse') {
      this.state.unshift(notification)
    } else {
      this.state.push(notification)
    }
  },
  notify(notification) {
    console.log('notify', this);
    //console.log('notify', app.config.globalProperties.$notifications );
    console.log('notify', notification);
    if (Array.isArray(notification)) {
      notification.forEach(notificationInstance => {
        this.addNotification(notificationInstance);
      });
    } else {
      this.addNotification(notification);
    }
  }
}


const NotificationsPlugin = {
  install(app, options) {
    
    app.config.globalProperties.$notifications = NotificationStore;

    app.config.globalProperties.$notify =(Notificacion)=>
    {
      return app.config.globalProperties.$notifications.notify(Notificacion);
    } ;
    app.component('Notifications', Notifications);
    if (options) {
      NotificationStore.setOptions(options);
    }
  }
};

export default NotificationsPlugin;

//export default NotificationsPlugin;
