<!-- @format -->

<template>
  <Modal
    v-if="MostrarModal"
    :show="MostrarModal"
    modal-classes="modal-dialog-centered"
    :type="type"
    @close="closeModal"
  >
    <template #header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-title text-center"><i class="pe-2" :class="tipoIcono"></i>Nuevo {{ tipo }}</h5>
    </template>

    <template #body>
      <div class="p-3">
        <Cargando :cargando="cargando" :msg-error="msgError">
          <div class="d-grid gap-1">
            <div v-if="$slots.nuevo">
              <slot name="nuevo"></slot>
            </div>
            <div v-else>
              <MazInput v-model="Datos.etiqueta" label="Etiqueta" class="mb-campo" required>
                <template #left-icon>
                  <i class="fas fa-tag"></i>
                </template>
                <template #right-icon> </template>
              </MazInput>
              <MazTextarea v-model="Datos.descripcion" label="Comentarios" class="mb-campo" />
            </div>
            <hr />
            <MazCheckbox v-if="mostrarIrAEdicion" v-model="IrAEdicion" size="sm"> Editar al registrar </MazCheckbox>

            <button class="btn btn-success mb-2" @click="Agregar">Registrar</button>
          </div>
        </Cargando>
      </div>
    </template>
    <p><!--Vacio para generar--></p>
  </Modal>
</template>

<script>
import Modal from "@/Componentes/Modal.vue";
import Cargando from "@/Componentes/Cargando.vue";

export default {
  components: {
    Modal,
    Cargando,
  },
  props: {
    mostrarIrAEdicion: {
      type: Boolean,
      default: true,
      description: "Indica si se mostrará la opción de ir a edición",
    },
    msgError: {
      type: String,
      default: "",
      description: "Muestra el mensaje de error",
    },
    //Muestra que esta cargando
    cargando: { type: Boolean, default: true },

    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    mostrar: {
      type: Boolean,
      default: true,
    },
    //ej. Mercadotecnia
    etiqueta: {
      type: String,
      required: true,
      default: "Mercadotecnia",
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
    //Indica que se mostrará la opción de reactivar
    reactivar: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
  },
  emits: ["update:mostrar", "agregar", "update:datos", 'update:ir-a-edicion'],

  data() {
    return {
      IrAEdicion: true,
      MostrarModal: this.mostrar,
      Datos: {
        etiqueta: "",
        descripcion: "",
      },
    };
  },

  computed: {
    slots() {
      return Object.keys(this.$slots);
    },
  },
  watch: {
    IrAEdicion(valor) {
      this.$emit("update:ir-a-edicion", valor);
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaDatos() {
      this.$emit("update:datos", this.Datos);
    },
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },

    Agregar() {
      if (this.validarDatos != null && !this.validarDatos(this.Datos)) {
        return;
      }
      console.log("Agregar", JSON.stringify(this.Datos));
      this.$emit("agregar", this.Datos);
    },
  },
};
</script>
