<template>
  <div class="bg-light p-3">
    <div class="row">
      <div class="col">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1Punto"
          />
          <label class="form-check-label" for="flexRadioDefault1Punto">
            Retirar en un punto de entrega
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">{ Sucursal }</div>
      </div>
      <div class="row">
        <div class="col">{ Direccion }</div>
        <div class="col">{ nombre } - {numero telefonico}</div>
        <hr />
      </div>
      <div class="row">
        <div class="col">
          <router-link to="/">Ver punto en el mapa</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>