<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const isRTL = computed(() => store.state.isRTL);
</script>
<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-end">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="d-lg-flex">
            <div class="my-auto mt-4 ms-auto mt-lg-0">
              <div
                class="text-sm text-end copyright text-muted"
                :class="isRTL ? 'text-lg-end' : 'text-lg-start'"
              >
                ©
                {{ new Date().getFullYear() }}, creado con
                <i class="fa fa-heart"></i> para valorar.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
