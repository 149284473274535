<!-- @format -->

<!-- eslint-disable no-unused-vars -->
<!-- @format -->

<template>
  <div class="card shadow-lg bg-light p-3" id="personal">
    <div class="row">
      <div class="col-12">
        <h5>Empresa</h5>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <MazInput
          v-model="localLinkID"
          type="text"
          label="Id *único"
          @update:model-value="(value) => update('link-id', value)"
        />
      </div>
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <MazInput
          v-model="localNombre"
          type="text"
          label="Nombre(s)"
          @update:model-value="(value) => update('nombre', value)"
        />
      </div>
      <div class="col-lg-3 col-12 col-sm-12 col-md-12 mb-3 px-2">
        <MazInput
          v-model="localApellidoP"
          type="text"
          label="Apellido Paterno"
          @update:model-value="(value) => update('apellidoP', value)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5>Domicilio</h5>
      </div>
    </div>
    <div class="row">
      <div class="col mb-3 px-2">
        <MazInput
          v-model="localCalle"
          type="text"
          label="Calle"
          @update:model-value="(value) => update('dirCalle', value)"
        />
      </div>
      <div class="col mb-3 px-2">
        <MazInput
          v-model="localNumExterior"
          type="text"
          label="Número exterior"
          @update:model-value="(value) => update('dirExterior', value)"
        />
      </div>
      <div class="col mb-3 px-2">
        <MazInput
          v-model="localNumInterior"
          type="text"
          label="Interior"
          @update:model-value="(value) => update('dirInterior', value)"
        />
      </div>
      <div class="col mb-3 px-2">
        <MazInput
          v-model="localCodigoP"
          type="text"
          label="Código postal"
          @update:model-value="(value) => update('cp', value)"
        />
      </div>

      
    </div>
    <div class="row">
      <div class="col mb-3 px-2">
        <MazInput
          v-model="localMunicipioAlcaldia"
          type="text"
          label="Municipio"
          @update:model-value="(value) => update('municipio', value)"
        />
      </div>
      <div class="col mb-3 px-2">
        <MazInput
          v-model="localColonia"
          type="text"
          label="Colonia"
          @update:model-value="(value) => update('colonia', value)"
        />
      </div>
      <div class="col mb-3 px-2">
        <MazInput
          v-model="localCiudad"
          type="text"
          label="Ciudad"
          @update:model-value="(value) => update('ciudad', value)"
        />
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import { watch } from "vue";
import { ref } from "vue";

export default {
  props: {
    linkId: {
      type: String,
      default: "",
    },
    nombre: {
      type: String,
      default: "",
    },
    apellidoP: {
      type: String,
      default: "",
    },
    apellidoM: {
      type: String,
      default: "",
    },
    genero: {
      type: String,
      default: "",
    },
    nacionalidad: {
      type: String,
      default: "",
    },
    curp: {
      type: String,
      default: "",
    },
    fechaNacimiento: {
      type: String,
      default: "",
    },
    loading: { type: Boolean, default: false },

    value: { type: String, default: null },
    id: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },

    // Same as MazInput (options: `sm|md|lg`)
    size: { type: String, default: null },

    optionSize: { type: String, default: null },

    // Etiqueta a mostrar
    campo: { type: String, default: "Genero" },

    // Etiqueta a mostrar
    placeholder: { type: String, default: "Genero" },
    // Enable the dark mode
    dark: { type: Boolean, default: false },
    // Use color
    color: { type: String, default: "primary" },

    success: { type: Boolean, default: false },

    open: { type: Boolean, default: false },

    // set the position of countries list (ex: `top`, `top right`, `bottom right`)
    position: { type: String, default: "left bottom" },

    clearable: { type: Boolean, default: true },
  },
  emits: [
    "update:link-id",
    "update:nombre",
    "update:apellido-p",
    "update:apellido-m",
    "update:genero",
    "update:nacionalidad",
    "update:curp",
    "update:fecha-nacimiento",
  ],
  setup() {
    const localFechaIngreso = ref(new Date());

    // Configuración del formato de fecha para mostrar solo mes y año
    const dateFormat = "MM/YYYY";

    // Opciones del picker
    const pickerOptions = {
      // Aquí puedes agregar más opciones según la documentación de maz-ui
    };

    return {
      localFechaIngreso,
      dateFormat,
      pickerOptions,
    };
  },
  data() {
    return {
      localLinkID: this.linkId,
      localNombre: this.nombre,
      localApellidoP: this.apellidoP,
      localApellidoM: this.apellidoM,
      localGenero: this.genero,
      localNacionalidad: this.nacionalidad,
      localCurp: this.curp,
      localFechaNacimiento: this.fechaNacimiento,

      MostrarNuevo: false,
      selectedItem: null,
      cargando: false,

      inputValue: "",
      Erroneo: "",
      valores: [
        { label: "Desconocido", value: "Desconocido" },
        { label: "Masculino", value: "Masculino" },
        { label: "Femenino", value: "Femenino" },
        { label: "Otro", value: "Otro" },
      ],
    };
  },
  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {
    watch(
      () => this.linkId,
      (newVal) => {
        this.localLinkID = newVal;
      }
    );
    watch(
      () => this.nombre,
      (newVal) => {
        this.localNombre = newVal;
      }
    );
    watch(
      () => this.apellidoP,
      (newVal) => {
        this.localApellidoP = newVal;
      }
    );
    watch(
      () => this.apellidoM,
      (newVal) => {
        this.localApellidoM = newVal;
      }
    );
    watch(
      () => this.genero,
      (newVal) => {
        this.localGenero = newVal;
      }
    );
    watch(
      () => this.nacionalidad,
      (newVal) => {
        this.localNacionalidad = newVal;
      }
    );
    watch(
      () => this.curp,
      (newVal) => {
        this.localCurp = newVal;
      }
    );
    watch(
      () => this.fechaNacimiento,
      (newVal) => {
        this.localFechaNacimiento = newVal;
      }
    );
  },
  computed: {
    error() {
      if (this.required)
        if (this.selectedItem == "Desconocido" || this.value == null)
          return true;
      return false;
    },
    Config() {
      {
        return {
          labelKey: "label",
          searchKey: "label",
          valueKey: "value",
        };
      }
    },
  },

  watch: {
    value(valor) {
      this.selectedItem = valor;
      console.debug(valor);
    },
    selectedItem(valor) {
      //this.$emit('update:value', valor);
      this.$emit("input", valor);
      //  this.$set(this, 'value', valor);
      // this.value = valor;
    },
  },
  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },

    OnGuardado(Datos) {
      this.valores.push(Datos);
      this.selectedItem = Datos.id;
      /*
            //Actualiza todo el listado
          this.Actualiza(() => {
            this.selectedItem = Datos.id;
          });
          */
    },

    Actualiza(OnFinalizado) {},
  },
  mounted() {
    if (this.value != null) this.selectedItem = this.value;
    this.Actualiza();
  },
};
</script>
  <style scoped>
.option {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
  