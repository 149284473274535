<!-- @format -->

<template>
  <li>
    <a class="dropdown-item border-radius-md" href="javascript:;">
      <h6 class="mb-1">
        <slot>

        </slot>
      </h6>
    </a>
  </li>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
 
};
</script>
