<!-- @format -->

<template>
  <MazPagination v-bind="Propiedades" v-model="PaginaActual" size="sm" />
</template>

<script>
import { watch } from "vue";

export default {
  props: {
    paginaLen: {
      type: Number,
      default: 50,
    },
    paginaNo: {
      type: Number,
      default: 1,
    },
    totalPaginas: {
      type: Number,
      default: 1,
    },
  },
  emits: ["update:paginaNo"],
  data() {
    return {
      localPaginaNo: this.paginaNo,
    };
  },
  computed: {
    Propiedades() {
      return {
        resultSize: this.paginaLen,
        totalPages: this.totalPaginas,
        activeColor: "secondary",
      };
    },
    PaginaActual: {
      get() {
        return this.localPaginaNo + 1;
      },
      set(value) {
        var PaginaNo = value - 1;
        this.localPaginaNo = PaginaNo;
        //  console.log("PaginaActual", PaginaNo);
        this.$emit(`update:paginaNo`, PaginaNo);
      },
    },
  },
  created() {
    watch(
      () => this.paginaNo,
      (newVal) => {
        this.localPaginaNo = newVal;
      }
    );
  },

  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
