<template>
    <Edicion :servicio="Servicio" @update:datos="(value) => (Datos = value)" :mostrar-preguntas="false">
      <template #Etiqueta>
      </template>
    </Edicion>
  </template>
  
  <script>
  import Servicio from "@/api-services/ubicaciones.service";
  import Edicion from "@/Componentes/Listados/Edicion.vue";

  export default {
    name: "UbicacionesEdicion",
    components: {
      Edicion,
    },
    data() {
      return {
        Datos: {},
      };
    },
    computed: {
      Servicio() {
        return Servicio;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>