<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col card mb-3 ms-4">
          <Calificaciones />
        </div>
        <div class="col card mb-3 ms-4">
          <GradientLineChart
            id="chart-line"
            title="Line chart with gradient"
            :chart="{
              labels: [
                'Dic',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              datasets: [
                {
                  label: 'Mobile Apps',
                  data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                },
                {
                  label: 'Websites',
                  data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
                },
              ],
            }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col card mb-3 ms-4">
          <!-- Inicia Seguimiento de evaluaciones -->
          
          <DoughnutChart
            :chart="{
              labels: [
                'Creative Tim',
                'Github',
                'Bootsnipp',
                'Dev.to',
                'Codeinwp',
              ],
              datasets: {
                label: 'Projects',
                data: [15, 20, 12, 60, 20],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.6)',
                  'rgba(54, 162, 235, 0.6)',
                  'rgba(255, 206, 86, 0.6)',
                ],
              },
            }"
          />
        </div>
        <div class="col card mb-3 ms-4">
          <BubbleChart
            title="Bubble Chart"
            height="350"
            :chart="{
              labels: [
                '0',
                '10',
                '20',
                '30',
                '40',
                '50',
                '60',
                '70',
                '80',
                '90',
                '100',
              ],
              datasets: [
                {
                  label: 'Dataset 1',
                  data: [
                    {
                      x: 50,
                      y: 350,
                      r: 15,
                    },
                    {
                      x: 100,
                      y: 250,
                      r: 15,
                    },
                  ],
                },
                {
                  label: 'Dataset 2',
                  data: [
                    {
                      x: 100,
                      y: 210,
                      r: 5,
                    },
                    {
                      x: 20,
                      y: 210,
                      r: 5,
                    },
                  ],
                },
              ],
            }"
          />
        </div>
        <div class="col card mb-3 ms-4">
          <DefaultLineChart
            id="line-chart"
            title="Distribución Género de los clientes"
            :chart="{
              labels: [
                '18-20',
                '21-23',
                '24-27',
                '28-31',
                '32-34',
                '35-37',
                '38-40',
                '40-o más',
              ],
              datasets: [
                {
                  label: 'Femenino',
                  data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                },
                {
                  label: 'Masculino',
                  data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
                },
                {
                  label: 'Otro',
                  data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
                },
              ],
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BubbleChart from "./Componentes/BubbleChart.vue";
import Calificaciones from "./Componentes/Calificaciones.vue";
/* import ChannelsChartCard from "./Componentes/ChannelsChartCard.vue"; */
/* import Charts from "./Componentes/Charts.vue"; */
import DefaultLineChart from "./Componentes/DefaultLineChart.vue";
/* import DistribucionGenero from "./Componentes/DistribucionGenero.vue"; */
import DoughnutChart from "../Dashboards/Componentes/DoughnutChart.vue";
import GradientLineChart from "./Componentes/GradientLineChart.vue";
/* import IndiceRetorno from "./Componentes/IndiceRetorno.vue"; */
/* import RevenueChartCard from "./Componentes/RevenueChartCard.vue"; */
/* import ReportsDoughnutChart from "@/Vistas/Dashboards/Componentes/ReportsDoughnutChart.vue"; */

export default {
  name: "Dashboard",
  components: {
    BubbleChart,
    Calificaciones,
    /* ChannelsChartCard, */
    /* Charts, */
    DefaultLineChart,
    /* DistribucionGenero, */
    DoughnutChart,
    GradientLineChart,
    /* IndiceRetorno, */
    /* RevenueChartCard, */
    /* ReportsDoughnutChart, */
  },
};
</script>
