<!-- @format -->

<template>
  <div class="card mx-2 p-3 mx-lg-4 p-lg-4">
    <div class="d-flex justify-content-between">
      <registros-x-pagina
        :value="localBaseGetsListado.PaginaLen"
        @update="
          (value) => {
            localBaseGetsListado.PaginaLen = value;
            Actualiza(0);
          }
        "
      />

      <div class="d-flex justify-content-end">
        <button class="btn btnCustom text-muted" :to="{}" @click="MostrarFiltro = !MostrarFiltro">
          <i class="fas fa-filter text-primary"></i>
        </button>

        <button :to="{}" class="btn btnCustom text-muted" @click="Actualiza()">
          <i class="fas fa-sync-alt text-primary"></i>
        </button>

        <!--Iconox-->
        <button :to="{}" @click="MostrarNuevo = !MostrarNuevo" class="btn btn-outline-primary">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-9 d-inline-flex">
        <i class="fa-2x ms-1 mt-2 me-2" :class="tipoIcono"></i>
        <p>{{ parrafo }}</p>
      </div>
    </div>

    <Cargando :cargando="cargando" :msg-error="msgError">
      <div v-if="info != null">
        <div v-if="$slots.listado">
          <slot name="listado" :eventos="eventos"></slot>
        </div>
        <div v-else class="card-columns-listado">
          <CardRegistro
            v-for="registro in info.listado"
            :key="registro.id"
            :registro="registro"
            @editar="Editar"
            @borrar="Borrar"
            @reactivar="Reactivar"
          />
        </div>
        <hr />
        <div class="d-lg-flex">
          <div class="ms-auto">
            <Paginacion
              :pagina-len="info.parametros.paginaLen"
              :pagina-no="info.parametros.paginaNo"
              :total-paginas="info.totalPaginas"
              @update:pagina-no="(value) => Actualiza(value)"
            />
          </div>
        </div>
      </div>
    </Cargando>
    <ListadoFiltro
      v-if="MostrarFiltro"
      :base-gets-listado="localBaseGetsListado"
      @update:mostrar="(value) => (MostrarFiltro = value)"
      @update:base-gets-listado="
        (value) => {
          localBaseGetsListado = value;
          Actualiza();
        }
      "
    />
    <ModalBorrar
      v-if="MostrarBorrar"
      :tipo="tipo"
      :etiqueta="RegistroABorrar.etiqueta"
      :reactivar="RegistroABorrar.borrado"
      @update:mostrar="(value) => (RegistroABorrar = null)"
      @borrar="(value) => BorrarSinPreguntar()"
      @reactivar="(value) => ReactivarSinPreguntar()"
    />
    <ModalNuevo
      v-if="MostrarNuevo"
      :tipo="tipo"
      :tipo-icono="tipoIcono"
      :validar-datos="validarDatos"
      :cargando="cargando"
      :msg-error="msgError"
      :type="nuevoType"
      :mostrar-ir-a-edicion="mostrarIrAEdicion"
      @update:ir-a-edicion="(valor) => (IrAEdicion = valor)"
      @update:mostrar="(value) => (MostrarNuevo = value)"
      @update:datos="(datos) => ActualizaDatosNuevo(datos)"
      @agregar="(datos) => Agregar(datos)"
    >
      <template v-if="$slots.nuevo" #nuevo>
        <slot name="nuevo"></slot>
      </template>
    </ModalNuevo>
  </div>
</template>

<script>
import Paginacion from "@/Componentes/Listados/Paginacion.vue";
import Cargando from "@/Componentes/Cargando.vue";
import CardRegistro from "@/Componentes/Listados/CardRegistro.vue";
import RegistrosXPagina from "@/Componentes/Listados/RegistrosXPagina.vue";
import ListadoFiltro from "./Listados/ListadoFiltro.vue";
import ModalBorrar from "@/Componentes/Listados/ModalBorrar.vue";
import ModalNuevo from "@/Componentes/Listados/ModalNuevo.vue";

export default {
  name: "ListadoGeneral",
  components: {
    Paginacion,
    Cargando,
    CardRegistro,
    RegistrosXPagina,
    ListadoFiltro,
    ModalBorrar,
    ModalNuevo,
  },
  props: {
    mostrarIrAEdicion: {
      type: Boolean,
      default: true,
      description: "Indica si se mostrará la opción de ir a edición",
    },
    validarDatos: {
      type: Function,
      default: () => {
        return true;
      },
    },
    info: {
      type: Object,
      default: null,
    },
    cargando: {
      type: Boolean,
      default: true,
    },
    msgError: {
      type: String,
      default: "",
    },
    titulo: {
      type: String,
      default: "Listado General",
    },
    parrafo: {
      type: String,
      default: "Parrafo General",
    },
    baseGetsListado: {
      type: Object,
      default: () => {
        return {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 10,
          Filtro: "",
        };
      },
    },

    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
    nuevoType: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini", "large"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") ',
    },
  },
  emits: ["agregar", "actualiza", "borrar", "editar", "reactivar", "update:datos-nuevo"],
  data() {
    return {
      IrAEdicion: true,

      RegistroABorrar: null,
      MostrarFiltro: false,
      MostrarNuevo: false,
      MostrarModalAdd: false,
      localBaseGetsListado: JSON.parse(JSON.stringify(this.baseGetsListado)),
      eventos: {
        onEditar: this.Editar,
        onBorrar: this.Borrar,
        onReactivar: this.Reactivar,
      },
    };
  },
  computed: {
    MostrarBorrar() {
      return this.RegistroABorrar !== null;
    },
    slots() {
      return Object.keys(this.$slots);
    },
  },
  mounted() {
    this.Actualiza();
  },
  methods: {
    onBorrar(registro) {
      console.log("onBorrar", registro);
    },
    ActualizaDatosNuevo(Datos) {
      this.$emit("update:datos-nuevo", Datos);
    },
    Agregar(registro) {
      this.$emit("agregar", {
        registro: registro,
        OnAgregado: this.OnAgregado,
        OnError: this.OnError,
      });
      /*  console.log("Agregar", registro);
      this.$emit("agregar", registro);*/
    },
    Editar(registro) {
      console.log("Editar", registro);
      this.$emit("editar", registro);
    },
    Borrar(registro) {
      console.log("Borrar", registro);
      this.RegistroABorrar = registro;
      //this.$emit("borrar", registro);
    },
    Reactivar(registro) {
      console.log("Reactivar", registro);
      this.RegistroABorrar = registro;
      //this.$emit("borrar", registro);
    },
    BorrarSinPreguntar() {
      this.$emit("borrar", {
        registro: this.RegistroABorrar,
        OnBorrado: this.OnBorrado,
        OnError: this.OnError,
      });
    },
    OnAgregado(response) {
      var ID = response.data.id;
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.IrAEdicion && ID) this.$router.push({ path: `${this.$route.path}/${ID}` });
          else {
            this.Actualiza();
            this.MostrarNuevo = false;
          }
        });
      }, 100);
      /*
      this.$nextTick(() => {
        this.Actualiza();
        this.MostrarNuevo = false;
      });*/
    },
    OnBorrado(response) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.Actualiza();
          this.RegistroABorrar = null;
        });
      }, 100);
      /*    this.$nextTick(() => {
        this.Actualiza();
        this.RegistroABorrar = null;
      });*/
    },
    OnError(MsgError, Error) {
      console.debug("Error", MsgError);
      if (Error && Error.response) console.debug("Error.response", Error.response.data);
    },
    ReactivarSinPreguntar() {
      //      console.log("Reactivar", registro);

      this.$emit("reactivar", {
        registro: this.RegistroABorrar,
        OnBorrado: this.OnBorrado,
        OnError: this.OnError,
      });
    },
    //Actualiza datos asignando la pagina actual
    Actualiza(paginaNo) {
      if (paginaNo != null) {
        this.localBaseGetsListado.PaginaNo = paginaNo;
        // Emitir un evento con el valor seleccionado para que el componente padre pueda reaccionar
        this.$emit("actualiza", this.localBaseGetsListado);
      } else this.$emit("actualiza", this.localBaseGetsListado);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-columns-listado {
  display: flex;
  flex-wrap: wrap;
  margin: -7px; /* Ajusta este valor para controlar el espaciado general entre tarjetas */
}

.card {
  display: flex; /* Habilita flexbox para el contenido de la tarjeta */
  margin: 7px; /* Espaciado alrededor de cada tarjeta */
  flex-basis: calc(100% - 14px); /* Ajusta este valor según el número deseado de tarjetas por fila */
  min-height: 5.2em;
}

.btnCustom {
  box-shadow: none !important;
  border: none;
}

@media (min-width: 35em) {
  .card {
    flex-basis: calc(100% / 2 - 14px); /* 2 tarjetas por fila */
  }
}

@media (min-width: 65em) {
  .card {
    flex-basis: calc(100% / 3 - 14px); /* 3 tarjetas por fila */
  }
}

@media (min-width: 97em) {
  .card {
    flex-basis: calc(100% / 4 - 14px); /* Mantenemos 4 tarjetas por fila */
  }
}

@media (min-width: 114em) {
  .card {
    flex-basis: calc(100% / 5 - 14px); /* 5 tarjetas por fila */
  }
}

@media (min-width: 130em) {
  .card {
    flex-basis: calc(100% / 6 - 14px); /* 6 tarjetas por fila */
  }
}
@media (min-width: 150em) {
  .card {
    flex-basis: calc(100% / 7 - 14px); /* 7 tarjetas por fila */
  }
}
</style>
