<template>
  <div class="m-3">
    <div class="container card">
      <div class="row p-4 d-flex justify-content-around">
        <div class="col">
          <div class="container">
            <div class="row">
              <div class="col-1">
                <img
                  width="60"
                  height="60"
                  class="rounded-circle"
                  :src="FotoURL"
                  alt=""
                />
              </div>
              <div class="col-11">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <p>Fer Rosas</p>
                      <small>Mesera</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-center align-items-center">
          <div class="container">
            <div class="row">
              <div class="col-2">
                <router-link to="/personal/calificaciones" @click.stop="">
                  <p class="p-0 m-0 linksRouter">
                    <b>4.5</b>
                    <i class="fa fa-star ms-2"></i>
                  </p>
                </router-link>
              </div>
              <div class="col-2">
                <router-link to="/personal/calificaciones" @click.stop="">
                  <p class="p-0 m-0 linksRouter">
                    <b>134</b>
                    <i class="fa fa-check-double ms-2"></i></p
                ></router-link>
              </div>
              <div class="col-7">
                <MenuCalificacionesColaboradores v-model="Seleccion" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Seleccion == 'Calificaciones'"
      class="mt-4 row d-flex justify-content-between"
    >
      <!-- Inicia la importacion de componente CalificacionesGraficas -->
      <CalificacionesGraficas />
      <!-- Termina la importacion de componente CalificacionesGraficas -->
    </div>
    <div v-else class="mt-4 row d-flex justify-content-between">
      <!-- Inicia la importacion de componente EvaluacionesColaborador -->
      <EvaluacionesColaborador />
      <!-- Termina la importacion de componente EvaluacionesColaborador -->
    </div>
  </div>
</template>

<script>
import CalificacionesGraficas from "@/Vistas/Personal/Componentes/CalificacionesGraficas.vue";
import EvaluacionesColaborador from "@/Vistas/Personal/Componentes/EvaluacionesColaborador.vue";
import MenuCalificacionesColaboradores from "@/Vistas/Personal/Componentes/MenuCalificacionesColaboradores.vue";
import Servicio from "@/api-services/colaboradores.service";

export default {
  name: "CalificacionesColaborador",
  components: {
    CalificacionesGraficas,
    EvaluacionesColaborador,
    MenuCalificacionesColaboradores,
  },
  props: {
    registro: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      Seleccion: "",
    };
  },
  computed: {
    FotoURL() {
      let img = Servicio.FotoURL(this.registro);
      return img;
    },
  },
  methods: {
    actualizarValor(nuevoValor) {
      this.Seleccion = nuevoValor;
      // console.debug(nuevoValor);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>