<template>
  <div class="card shadow-lg bg-light p-3">
    <div class="row">
      <div class="col">
        <h5>Datos de acceso</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>
          Es importante que llenes correctamente estos datos, ya que con ellos
          podrán tener acceso a la plataforma y poder observar sus
          calificaciones, así como también poder comentar sobre ellas.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-4 col-sm-6">
        <p>*Servirá para tu inicio de sesión</p>
      </div>
      <div class="col-6 col-sm-6">
        <p>*Servirá para poder recibir el acceso a tu plataforma</p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-4 col-sm-6">
        <MazInput
          v-model="localEmailPersonal"
          type="text"
          label="Email personal"
          @update:model-value="(value) => update('correo', value)"
        />
      </div>
      <div class="col-5 col-sm-6">
        <Telefono
          v-model="localMovilPersonal"
          label="Móvil personal"
          :class="EstiloCampo"
          @update:model-value="(value) => update('telMovilPersonal', value)"
        />
      </div>
    </div>
    <h6>Accesos a la plataforma</h6>
    <div class="row mb-3">
      <div class="col-4">
        <div class="form-check">
          <input
            v-model="localAcceso"
            class="form-check-input"
            type="radio"
            name="RadioAccesoAdmin"
            value="Administrador"
            id="flexRadioAccAdmin"
            @update:model-value="(value) => update('perfil', value)"
          />
          <label class="form-check-label" for="flexRadioAccAdmin">
            Administrador
          </label>
        </div>
      </div>
      <div class="col-4">
        <div class="form-check">
          <input
            v-model="localAcceso"
            class="form-check-input"
            type="radio"
            name="RadioAccesoAutoservicio"
            value="Colaborador"
            id="flexRadioAccAutoservicio"
            @update:model-value="(value) => update('perfil', value)"
          />
          <label class="form-check-label" for="flexRadioAccAutoservicio">
            Autoservicio
          </label>
        </div>
      </div>
      <!-- <p>{{localAcceso}}</p> -->
    </div>
  </div>
</template>

<script>
import { watch } from "vue";
import Telefono from "@/Componentes/Telefono.vue";

export default {
  components: {
    Telefono,
  },
  props: {
    correo: {
      type: String,
      default: "",
    },
    telMovilPersonal: {
      type: String,
      default: "",
    },
    perfil: {
      type: String,
      default: "",
    },
  },
  emits: ["update:correo", "update:telMovilPersonal", "update:perfil"],
  data() {
    return {
      localEmailPersonal: this.correo,
      localMovilPersonal: this.telMovilPersonal,
      localAcceso: this.perfil,
    };
  },
  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {
    watch(
      () => this.correo,
      (newVal) => {
        this.localEmailPersonal = newVal;
      }
    );
    watch(
      () => this.telMovilPersonal,
      (newVal) => {
        this.localMovilPersonal = newVal;
      }
    );
    watch(
      () => this.perfil,
      (newVal) => {
        this.localPerfil = newVal;
      }
    );
  },
  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },
  },
};
</script>

 