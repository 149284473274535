<!-- @format -->

<template>
  <MazSelect
    v-model="Seleccionado"
    :label="TipoMayuscula"
    :options="Listado"
    option-value-key="id"
    option-label-key="etiqueta"
    option-input-value-key="etiqueta"
    search-placeholder="Buscar..."
    search
  >
    <template #left-icon>
      <i class="fas fa-tag"></i>
    </template>
    <template #right-icon>
      <i :class="tipoIcono">12</i>
    </template>
  </MazSelect>
</template>

<script>
export default {
  name: "ListadosSelect",
  components: {
    /* ModalEditarColaborador, */
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
  },
  data() {
    return {
      info: null,

      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      GetsResponse: null,
      Seleccionado: null,
    };
  },
  computed: {
    TipoMayuscula() {
      return this.tipo.charAt(0).toUpperCase() + this.tipo.slice(1);
    },
    Listado() {
      if (this.info == null || this.info.listado == null) return [];
      return this.info.listado;
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaDatos() {
      if (this.service != null)
        this.service.getsSelect(this, (response) => {
          this.info = response.data;
        });
    },
  },
};
</script>
