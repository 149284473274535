<!-- @format -->

<template>
  <ListadoSelect
    v-model="Seleccionado"
    :tipo="tipo"
    tipo-icono="fas fa-sitemap"
    :service="Service"
   
  />
</template>

<script>
import Service from "@/api-services/departamentos.service";
import ListadoSelect from "@/Componentes/Listados/Select.vue";
export default {
  name: "DepartamentosSelect",
  components: {
    ListadoSelect,
  },
  props: {
    tipo: {
      type: String,
      required: false,
      default: "departamento",
    },
  },
  data() {
    return {

      Seleccionado: null,
    };
  },
  computed: {
    Service() {
      return Service;
    },

  },

};
</script>
