<template>
  <div class="card p-3">
    <div class="row">
      <div class="col-12">
        <small>Fecha de nacimiento</small>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-check">
            <input
              v-model="localCampoFecha"
              class="form-check-input"
              type="radio"
              name="RadioFechaNacOpt"
              value="Opcional"
              id="flexRadioFecNacOpcional"
            />
            <label class="form-check-label" for="flexRadioFecNacOpcional">
              Opcional
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-check">
            <input
              v-model="localCampoFecha"
              class="form-check-input"
              type="radio"
              name="RadioFechaNacObligatorio"
              value="Obligatorio"
              id="flexRadioFecNacObligatorio"
            />
            <label class="form-check-label" for="flexRadioFecNacObligatorio">
              Obligatorio
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: { 
    campoFecha: {
      type: String,
      default: "",
    },
  },
  emits: ["update:campoFecha"],
  data() {
    return {
      localCampoFecha: this.campoFecha,
    };
  },
  watch: {
    localCampoFecha(Valor) {
      this.$emit("update:campoFecha", Valor);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>