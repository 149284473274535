<template>
  <div>
    <table class="container">
      <thead>
        <tr class="row m-3">
          <td
            v-for="(columna, index) in datos[0]"
            :key="index"
            class="col m-2 card d-flex justify-content-center align-items-center"
          >
            {{ columna }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(fila, rowIndex) in datos.slice(1)"
          :key="rowIndex"
          class="row m-3"
        >
          <td
            v-for="(columna, colIndex) in fila"
            :key="colIndex"
            class="col m-2 card d-flex justify-content-center align-items-center"
          >
            {{ columna }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Tabla",
  props: {
    datos: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
/* @font-face {
  font-family: 'Poppins-Regular';
  src: url('../assets/fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
} */
table.container{
  font-family: 'Poppins-Regular';
  font-weight: 200;
  font-style: normal;
}
/* Estilos opcionales para ajustar el diseño según sea necesario */
.container {
  margin-top: 20px;
}
</style>