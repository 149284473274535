<template>
    <div>
        <div style="cursor: pointer;" class="p-3" @click="Atras">
            <h3 style="gap: 15px; color: white; font-weight: bold;" class="d-flex">
                <i class="fas fa-arrow-left mt-2"></i>
                Fotografia del colaborador
            </h3>
        </div>
        <CardTomaFoto @importar="ImportarFoto" />
    </div>
</template>

<script>
import CardTomaFoto from './Componentes/CardTomaFoto.vue';
import Colaboradores from '@/api-services/colaboradores.service';
export default {
    name: "TomaFoto",
    components: {
        CardTomaFoto,
    },
    props: {

    },
    data() {
        return {
            
        }
    },
    mounted() {

    },

    methods: {
        Atras() {
            if (window.history.length > 2) this.$router.go(-1);
            else this.$router.go("/personal");
        },
        ImportarFoto(Foto){
            Colaboradores.FotoPatch(this,this.$route.params.id,0,Foto,(progreso) => {}, (response) => {
                console.debug("La respuesta es:",response.data)
                this.$notify({ type: "success", title: "Foto guardada" });
            })
        },
    },
}
</script>

<style></style>