<!-- @format -->

<!-- eslint-disable no-unused-vars -->
<!-- @format -->

<template>
  <div class="card shadow-lg bg-light p-3" id="personal">
    <div class="row">
      <div class="col-12">
        <h5>Acccesos a la plataforma</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <MazSelect
          label="Accesos"
          v-model="selectedUser"
          :options="customTemplateOptions"
          search
        >
          <div class="flex items-center" style="width: 100%; gap: 1rem">
            <MazAvatar size="0.8rem" :src="option.picture" />
            <strong>
              {{ option.label }}
            </strong>
          </div>
        </MazSelect>
      </div>
      <!-- <div class="col">
        <div class="card card-blog card-plain">
          <div class="position-relative">
            <a class="shadow-xl d-block border-radius-xl">
              <img
                :src="img"
                alt="img-blur-shadow"
                class="shadow img-fluid border-radius-xl"
              />
            </a>
          </div>
          <div class="px-1 pb-0 card-body">
            <p class="mb-2 text-sm text-gradient text-dark">{{ label }}</p>
            <a href="javascript:;">
              <h5>{{ title }}</h5>
            </a>
            <p class="mb-4 text-sm">
              {{ description }}
            </p>
            <div class="d-flex align-items-center justify-content-between">
              <button
                type="button"
                class="mb-0 btn btn-sm"
                :class="`btn-outline-${action.color}`"
              >
                {{ action.label }}
              </button>
              <div class="mt-2 avatar-group">
                <a
                  v-for="({ image: authorImage, name }, index) of authors"
                  :key="index"
                  href="javascript:;"
                  class="avatar avatar-xs rounded-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  :title="name"
                >
                  <img :alt="authorImage" :src="authorImage" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col">
        <div class="container">
          <div class="row">
            <div class="col">
              <MazCheckbox v-model="checked"> Acceso a colaboradores </MazCheckbox>
            </div>
            <div class="col">
              <MazCheckbox v-model="checked"> Acceso a departamentos </MazCheckbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <MazCheckbox v-model="checked"> Acceso a encuesta personalizada </MazCheckbox>
            </div>
            <div class="col">
              <MazCheckbox v-model="checked"> Acceso a puestos </MazCheckbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import { watch } from "vue";
import { ref } from "vue";

export default {
  props: {
    linkId: {
      type: String,
      default: "",
    },
    nombre: {
      type: String,
      default: "",
    },
    apellidoP: {
      type: String,
      default: "",
    },
    apellidoM: {
      type: String,
      default: "",
    },
    genero: {
      type: String,
      default: "",
    },
    nacionalidad: {
      type: String,
      default: "",
    },
    curp: {
      type: String,
      default: "",
    },
    fechaNacimiento: {
      type: String,
      default: "",
    },
    loading: { type: Boolean, default: false },

    value: { type: String, default: null },
    id: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },

    // Same as MazInput (options: `sm|md|lg`)
    size: { type: String, default: null },

    optionSize: { type: String, default: null },

    // Etiqueta a mostrar
    campo: { type: String, default: "Genero" },

    // Etiqueta a mostrar
    placeholder: { type: String, default: "Genero" },
    // Enable the dark mode
    dark: { type: Boolean, default: false },
    // Use color
    color: { type: String, default: "primary" },

    success: { type: Boolean, default: false },

    open: { type: Boolean, default: false },

    // set the position of countries list (ex: `top`, `top right`, `bottom right`)
    position: { type: String, default: "left bottom" },

    clearable: { type: Boolean, default: true },
    
  },
  emits: [
    "update:link-id",
    "update:nombre",
    "update:apellido-p",
    "update:apellido-m",
    "update:genero",
    "update:nacionalidad",
    "update:curp",
    "update:fecha-nacimiento",
  ],
  setup() {
    const localFechaIngreso = ref(new Date());

    // Configuración del formato de fecha para mostrar solo mes y año
    const dateFormat = "MM/YYYY";

    // Opciones del picker
    const pickerOptions = {
      // Aquí puedes agregar más opciones según la documentación de maz-ui
    };
    const checked = ref(false);
    return {
      localFechaIngreso,
      dateFormat,
      pickerOptions,
    };
  },
  data() {
    return {
      localLinkID: this.linkId,
      localNombre: this.nombre,
      localApellidoP: this.apellidoP,
      localApellidoM: this.apellidoM,
      localGenero: this.genero,
      localNacionalidad: this.nacionalidad,
      localCurp: this.curp,
      localFechaNacimiento: this.fechaNacimiento,

      MostrarNuevo: false,
      selectedItem: null,
      cargando: false,
      checked: false,
      inputValue: "",
      Erroneo: "",
      valores: [
        { label: "Desconocido", value: "Desconocido" },
        { label: "Masculino", value: "Masculino" },
        { label: "Femenino", value: "Femenino" },
        { label: "Otro", value: "Otro" },
      ],
    };
  },
  // Usar el hook created puede ser útil para inicializar datos o estados
  created() {
    watch(
      () => this.linkId,
      (newVal) => {
        this.localLinkID = newVal;
      }
    );
    watch(
      () => this.nombre,
      (newVal) => {
        this.localNombre = newVal;
      }
    );
    watch(
      () => this.apellidoP,
      (newVal) => {
        this.localApellidoP = newVal;
      }
    );
    watch(
      () => this.apellidoM,
      (newVal) => {
        this.localApellidoM = newVal;
      }
    );
    watch(
      () => this.genero,
      (newVal) => {
        this.localGenero = newVal;
      }
    );
    watch(
      () => this.nacionalidad,
      (newVal) => {
        this.localNacionalidad = newVal;
      }
    );
    watch(
      () => this.curp,
      (newVal) => {
        this.localCurp = newVal;
      }
    );
    watch(
      () => this.fechaNacimiento,
      (newVal) => {
        this.localFechaNacimiento = newVal;
      }
    );
  },
  computed: {
    error() {
      if (this.required)
        if (this.selectedItem == "Desconocido" || this.value == null)
          return true;
      return false;
    },
    Config() {
      {
        return {
          labelKey: "label",
          searchKey: "label",
          valueKey: "value",
        };
      }
    },
  },

  watch: {
    value(valor) {
      this.selectedItem = valor;
      console.debug(valor);
    },
    selectedItem(valor) {
      //this.$emit('update:value', valor);
      this.$emit("input", valor);
      //  this.$set(this, 'value', valor);
      // this.value = valor;
    },
  },
  methods: {
    update(campo, value) {
      // console.debug(`Actualizando ${campo} a ${value}`);
      this.$emit(`update:${campo}`, value);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },

    OnGuardado(Datos) {
      this.valores.push(Datos);
      this.selectedItem = Datos.id;
      /*
            //Actualiza todo el listado
          this.Actualiza(() => {
            this.selectedItem = Datos.id;
          });
          */
    },

    Actualiza(OnFinalizado) {},
  },
  mounted() {
    if (this.value != null) this.selectedItem = this.value;
    this.Actualiza();
  },
};
</script>
  <style scoped>
.option {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
  