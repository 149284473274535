<!-- @format -->

<template>
  <div class="card shadow-lg bg-light p-3">
    <div class="row">
      <div class="col">
        <h5>Domicilio</h5>
      </div>
    </div>
    <DomicilioComp v-model="localdDireccion" @update:model-value="(value) => update('localdDireccion', value)" />
  </div>
</template>

<script>
import DomicilioComp from "@/Componentes/Domicilio.vue";

export default {
  components: {
    DomicilioComp,
  },
  props: {
    dDireccion: {
      type: Object,
      default: () => ({
        pais: null,
        dirCalle: null,
        dirCalleNo: null,
        dirInterior: null,
        colonia: null,
        tipoAsentamiento: null,
        municipio: null,
        ciudad: null,
        estado: null,
        cp: null,
        gps: null,
      }),
    },
  },
  emits: ["update:dDireccion"],
  data() {
    return {
      localdDireccion: this.dDireccion,
    };
  },

  methods: {
    update(campo, value) {
      this.localdDireccion = value;
      this.$emit(`update:dDireccion`, this.localdDireccion);
    },
    cambiarMensaje() {
      this.mensaje = "¡Hola, Vue!";
    },
  },
};
</script>
