<template>
  <div id="sidenavCard" class="contenedor-ayuda card card-plain shadow-none">
    <!-- <img
      class="w-60 mx-auto"
      src="../../assets/img/logosya/Logoyacalifica767x199.png"
      alt="sidebar_illustration"
    /> -->

    <div class="container">
      <div class="row">
        <div class="col text-center" style="font-size: 5rem;">
          <i style="color: #2dce89;" class="fas fa-comments"></i>
        </div>
      </div>
    </div>
    <div class="p-3 card-body text-center w-100 pt-0">
      <div class="docs-info">
        <h6
          class="mb-0 up"
          :class="
            $store.state.sidebarType === 'bg-default' ||
            $store.state.darkMode ||
            $store.state.layout === 'landing'
              ? 'text-white'
              : 'text-dark'
          "
        >
          {{ card.title }}
        </h6>
        <p class="text-xs font-weight-bold">{{ card.description }}</p>
        <a
          v-for="({ label, route, color }, index) of card.links"
          :key="index"
          :href="route"
          target="_blank"
          :class="`btn btn-${color} btn-sm w-100 ${
            index === 0 ? 'mb-3' : 'mb-0'
          }`"
          >{{ label }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SidenavCard",
  props: {
    card: {
      type: Object,
      required: true,
      title: String,
      description: String,
      links: {
        type: Array,
        label: String,
        route: String,
        color: String,
      },
    },
  },
  data() {
    return {};
  },
};
</script>


<style lang="css" scoped>
.contenedor {
    position: relative;
}

.contenedor-ayuda {
    position: absolute;
    bottom: 0;
    width: 100%;
}
</style>
