<!-- @format -->

<template>
  <Modal v-if="MostrarModal" :show="MostrarModal" modal-classes="modal-dialog-centered" @close="closeModal">
    <template #header>
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <div></div>
    </template>
    <template #body>
      <div v-if="!reactivar" class="p-3">
        <div class="d-flex justify-content-center">
          <i class="fa-6x fas fa-question-circle text-danger"></i>
        </div>
        <div class="d-grid gap-1">
          <h5 class="card-title text-center">¿Estas seguro?</h5>
          <p class="text-center">{{ `Estas por borrar un ${tipo} (${etiqueta}).` }}</p>

          <button class="btn btn-danger mb-2" @click="Borrar">Si, Eliminar</button>
          <router-link class="text-center" :to="{}" @click="closeModal"
            ><i class="fa fa-chevron-left me-2"></i>Cancelar</router-link
          >
        </div>
      </div>
      <div v-if="reactivar" class="p-3">
        <div class="d-flex justify-content-center">
          <i class="fa-6x fas fa-question-circle text-info"></i>
        </div>
        <div class="d-grid gap-1">
          <h5 class="card-title text-center">¿Estas seguro?</h5>
          <p class="text-center">{{ `Estas por reactivar un ${tipo} (${etiqueta}).` }}</p>

          <button class="btn btn-info mb-2" @click="Reactivar">Si, Activarlo</button>
          <router-link class="text-center" :to="{}" @click="closeModal"
            ><i class="fa fa-chevron-left me-2"></i>Cancelar</router-link
          >
        </div>
      </div>
    </template>
    <p><!--Vacio para generar--></p>

  </Modal>
</template>

<script>
import Modal from "@/Componentes/Modal.vue";

export default {
  components: {
    Modal,
  },
  props: {
    mostrar: {
      type: Boolean,
      default: true,
    },
    //ej. Mercadotecnia
    etiqueta: {
      type: String,
      required: true,
      default: "Mercadotecnia",
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "departamento",
    },
    //Indica que se mostrará la opción de reactivar
    reactivar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:mostrar", "borrar", "reactivar"],

  data() {
    return {
      MostrarModal: this.mostrar,
    };
  },
  methods: {
    closeModal() {
      this.MostrarModal = false;
      this.$emit("update:mostrar", this.MostrarModal);
    },
    Borrar(registro) {
      // console.log("Borrar", registro);
      this.$emit("borrar", registro);
    },
    Reactivar(registro) {
      // console.log("reactivar", registro);
      this.$emit("reactivar", registro);
    },
  },
};
</script>

<style>
/* Estilos del componente */
</style>
