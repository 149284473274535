<!-- @format -->

<template>
  <MazInput
    v-model="Valor"
    type="email"
    :label="label"
    :error="failed"
    :success="passed"
    :autocomplete="AutoCompletarRandom"
    @update:model-value="Actualiza"
  >
    <template #right-icon>
      <div v-if="passed" class="text-success">
        <i class="fas fa-check p-2"></i>
      </div>
      <div v-else-if="failed" class="text-danger">
        <i class="fas fa-times p-2"></i>
      </div>
    </template>
  </MazInput>
</template>

<script>
export default {
  name: "CorreoInput",
  // Propiedades del componente aquí
  props: {
    modelValue: { type: String, default: null },
    label: {
      type: String,
      required: false,
      default: "Correo",
    },
  },
  emits: ["update:modelValue"],
  // Datos del componente aquí
  data() {
    return {
      Valor: this.modelValue == null ? "" : this.modelValue,
    };
  },
  computed: {
    AutoCompletarRandom() {
      return `${this.$options.name}-Auto-${Math.random()}`;
    },
    passed() {
      return this.Valida(this.Valor);
    },
    failed() {
      if (this.Valor == null || this.Valor == "") return false;
      return !this.Valida(this.Valor);
    },
  },

  watch: {
    modelValue(valor) {
      this.Valor = valor;
      return;
    },
    Valor(valor) {
      this.$emit("update:modelValue", valor);
    },
  },
  // Ciclo de vida del componente aquí
  created() {},
  mounted() {},
  // Otros hooks del ciclo de vida aquí
  // Métodos del componente aquí
  methods: {
    Valida(Valor) {
      const emailPattern =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;

      return emailPattern.test(Valor);
    },
    Actualiza(Valor) {
      this.Valor = Valor;
    },
  },
};
</script>

<style scoped>
/* Estilos del componente aquí */
</style>
