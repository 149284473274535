<script setup>
import { onMounted } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
  id: {
    type: String,
    default: "bubble-chart",
  },
  title: {
    type: String,
    default: "",
  },
  height: {
    type: [String, Number],
    default: "150",
  },
  chart: {
    type: Object,
    required: true,
    labels: Array,
    datasets: {
      type: Array,
      label: String,
      data: Array,
    },
  },
});
onMounted(() => {
  var ctx = document.getElementById(props.id).getContext("2d");

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }

  new Chart(ctx, {
    type: "bubble",
    data: {
      labels: ["Clientes"],
      datasets: [
        {
          label: props.chart.datasets[0].label,
          data: props.chart.datasets[0].data,
          backgroundColor: "#182B4D ",
          borderDash: [5, 5],
        },
        {
          label: props.chart.datasets[1].label,
          data: props.chart.datasets[1].data,
          backgroundColor: "#182B4D",
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [4, 4],
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#b2b9bf",
            font: {
              size: 10,
              family: "Open Sans",
              style: "normal",
              lineHeight: 2,
            },
          },
          type: "category", // Tipo de escala para el eje y
          position: "left", // Posición del eje y
          labels: [
            "15-18 veces\nPor mes",
            "6-10 veces\nPor mes",
            "3-5 veces\nPor mes",
            "1-2 veces\nPor mes",
          ], // Etiquetas personalizadas para los meses
        },
        x: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            min: 0,
          },
          ticks: {
            display: true,
            color: "#b2b9bf",
            padding: 9,
            font: {
              size: 11,
              family: "Open Sans",
              style: "normal",
              lineHeight: 2,
            },
          },
          type: "category", // Tipo de escala para el eje y
          position: "left", // Posición del eje y
          labels: ["", "Dic", "Ene", "Feb"], // Etiquetas personalizadas para los meses
        },
      },
    },
  });
});
</script>
<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-1 text-center mt-3"
        style="
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #21c997;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <i class="fa fa-file-alt" style="color: white; font-size: 1.5rem"></i>
      </div>
      <div class="col-9 mt-3">
        <h5>Indice de retorno de clientes</h5>
      </div>
    </div>
  </div>
  <div class="p-3 card-body">
    <div class="chart">
      <canvas
        :id="props.id"
        class="chart-canvas"
        :height="props.height"
      ></canvas>
    </div>
  </div>
</template>
