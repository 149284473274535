<!-- @format -->

<template>
  <MazSelect
    v-model="Seleccionado"
    :label="TipoMayuscula"
    :options="Listado"
    option-value-key="id"
    option-label-key="etiqueta"
    option-input-value-key="etiqueta"
  >
  </MazSelect>
</template>

<script>
export default {
  name: "GenerosSelect",
  components: {
    /* ModalEditarColaborador, */
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    //ej. departamento
    tipo: {
      type: String,
      required: true,
      default: "genero",
    },
    tipoIcono: {
      type: String,
      required: true,
      default: "fas fa-sitemap",
    },
  },
  data() {
    return {
      info: null,

      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      GetsResponse: null,
      Seleccionado: null,
    };
  },
  computed: {
    TipoMayuscula() {
      return this.tipo.charAt(0).toUpperCase() + this.tipo.slice(1);
    },
    Listado() {
      return [
        { etiqueta: "Desconocido", id: "Desconocido" },
        { etiqueta: "Masculino", id: "Masculino" },
        { etiqueta: "Femenino", id: "Femenino" },
        { etiqueta: "Otro", id: "Otro" },
      ];
    },
  },
  mounted() {
    this.ActualizaDatos();
  },
  methods: {
    ActualizaDatos() {
      if (this.service != null)
        this.service.getsSelect(this, (response) => {
          this.info = response.data;
        });
    },
  },
};
</script>
