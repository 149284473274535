<template>
  <div class="border rounded" style="background-color: #e9ecef">
    <!-- <p>Aqui se muestra la vista de Idiomas</p> -->
    <div class="container mt-4">
      <div class="row">
        <div class="col text-start">
          <p style="font-size: 1.5rem; font-family: 'Poppins-Regular'">
            Tabla de idiomas
          </p>
        </div>
        <div class="col text-end">
          <button class="me-1">
            <i class="fa fa-eye icon"></i>
          </button>
          <button class="btn_plus">
            <i class="fa fa-plus icon"></i>
          </button>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col d-flex justify-content-center align-items-center">
          <img
            src="../assets/img/logosya/flag.png"
            alt=""
            width="30"
            height="30"
          /><span>&nbsp;Español</span>
        </div>
        <div class="col d-flex justify-content-center align-items-center">
          <img
            src="../assets/img/logosya/united-states-of-america.png"
            alt=""
            width="30"
            height="30"
          /><span>&nbsp;Inglés</span>
        </div>
        <div class="col d-flex justify-content-center align-items-center">
          <img
            src="../assets/img/logosya/japan.png"
            alt=""
            width="30"
            height="30"
          /><span>&nbsp;Japonés</span>
        </div>
      </div>
      <hr />
    </div>
    <Tabla :datos="preguntas" />
    <div class="container p-5">
      <div class="row">
        <div
          v-for="button in 3"
          :key="button"
          class="col d-flex justify-content-center align-items-center"
        >
          <button type="button" class="btn btn-secondary" disabled>
            Eliminar idioma
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Tabla from "./Tabla.vue";

export default {
  name: "Idiomas",
  components: {
    Tabla,
  },
  props: {
    datos: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      preguntas: [
        [
          "¿Qué te pareció la calidad de nuestro servicio?",
          "What did you kind of the quality of our services",
          "¿新しいサービスを提供する必要がありますか?",
        ],
        [
          "En términos de limpieza y orden ¿Como calificarías nuestras instalaciones",
          "In terms of cleanlines and order, how would you rate our facilities",
          "清潔さと秩序の点で、当施設の評価はいかがですか?",
        ],
        [
          "¿La eficiencia de {{Colaborador.etiqueta}} en sus tareas fue satisfactoria?",
          "Was the efficiency of {{Colaborador.Sobrenombre}} in their tasks satisfactory?",
          "{{Colleague.label}} の任務における効率は満足のいくものでしたか?",
        ],
        [
          "¿Hubo algún aspecto destacado en {{Colaborador.etiqueta}}?",
          "Was there any outstanding aspect in {{Colaborador.Sobrenombre}}?",
          "{{Colleague.label}} に目立った側面はありましたか",
        ],
        [
          "¿Que tan satisfecho/a estas con el trabajo de {{Colaborador.etiqueta}}?",
          "How satisfied are you with the work of {{Colaborador.Sobrenombre}}?",
          "{{Colleague.label}} の仕事にどの程度満足していますか?",
        ],
      ],
    };
  },
};
</script>

<style lang="css" scoped>
/* @font-face {
  font-family: 'Poppins-Regular';
  src: url('../assets/fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
} */

p {
  font-family: "Poppins-Regular";
  font-weight: normal;
  font-size: 1rem;
}

span {
  font-family: "Poppins-Regular";
  font-weight: normal;
  font-size: 1rem;
}
.btn_plus {
  border: 1px solid #2dcec6;
  border-radius: 5px;
}
.icon {
  color: #2dcec6;
}
</style>