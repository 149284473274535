<!-- @format -->

<template>
  <router-link class="card bg-light bg-gradient p-2" :to="{ path: RutaDestino }" @click.stop="Editar(registro)">
    <div class="position-absolute end-2">
      <router-link :to="{}" class="p-2 mt-4" data-effect="fadeOut" @click.stop="Borrar(registro)"
        ><i class="fa fa-times text-primary"></i
      ></router-link>
    </div>
    <div class="d-flex justify-content-around">
      <div class="col-3 pt-2 text-center">
        <img class="img-fluid rounded-circle" :src="FotoURL" alt="profile card" />
        <h5>{{LinkID}}</h5>
      </div>
      <div class="col-9 p-2 text-center">
        <h4 class="mb-0 pb-0 me-2 text-break text-truncate">
          {{ Etiqueta }}
        </h4>
        <p class="mb-0 pb-0 text-muted text-truncate">{{ Descripcion }}</p>
        <hr class="mx-4 text-muted"/>
        <div class="d-flex justify-content-center">
          <router-link to="/personal/calificaciones" class="" @click.stop="">
            <b>4.5</b>
            <i class="fa fa-star ms-2"></i>
          </router-link>
          <router-link to="/personal/calificaciones" class="ms-4" @click.stop="">
            <b>134</b>
            <i class="fa fa-check-double ms-2"></i>
          </router-link>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Servicio from "@/api-services/colaboradores.service";
/* import ModalEditarColaborador from "./Modals/ModalEditarColaborador.vue"; */

export default {
  name: "PersonalCardRegistro",
  components: {
    /* ModalEditarColaborador, */
  },
  props: {
    registro: {
      type: Object,
      required: true,
    },
  },
  emits: ["editar", "borrar", "reactivar"],
  computed: {
    RutaDestino() {
      return `${this.$route.path}/${this.registro.id}`;
    },
    Puesto() {
      if (this.registro.puesto == null) return "";
      return this.registro.puesto.etiqueta;
    },
    FotoURL() {
      let img = Servicio.FotoURL(this.registro);
      return img;
    },
    LinkID() {
      if (this.registro == null) return "-";
      if (this.registro.linkID == "") return "-";
      return this.registro.linkID;
    },
    Etiqueta() {
      if (this.registro == null) return "-";
      if (this.registro.etiqueta == "") return "-";
      return this.registro.etiqueta;
    },
    Descripcion() {
      
      var Puesto = this.Puesto;
      if (Puesto == null || Puesto == "") return "-";

      return `${Puesto}`;
    },
  },
  mounted() {
    /* this.Actualiza(); */
  },
  methods: {
    Editar(registro) {
      console.log("Editar", registro);
      this.$emit("editar", registro);
    },
    Borrar(registro) {
      console.log("Borrar", registro);
      this.$emit("borrar", registro);
    },
    Reactivar(registro) {
      // console.log("reactivar", registro);
      this.$emit("reactivar", registro);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Estilos específicos del componente */

.profile-card {
  width: 100%;
  /* height: 20rem; */
  min-height: 200px;
  margin: auto;
  background: #f2f2f2;
  border-radius: 12px;
  max-width: 700px;
  position: relative;

  &.active {
    .profile-card__cnt {
      filter: blur(6px);
    }

    .profile-card-message,
    .profile-card__overlay {
      opacity: 1;
      pointer-events: auto;
      transition-delay: 0.1s;
    }

    .profile-card-form {
      transform: none;
      transition-delay: 0.1s;
    }
  }

  &__img {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    transform: translate(-50%, -50%); /* Ajusta para centrar completamente */

    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    z-index: 4;
    left: 50%; /* Centra el elemento horizontalmente */
    @media screen and (max-width: 576px) {
      width: 120px;
      height: 120px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.modal-header,
.modal-footer {
  padding: 10px 15px;
  background-color: #f2f2f2;
}

.modal-header .close {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-body {
  padding: 10px 15px;
}

.show {
  display: block;
}

.linksRouter p b {
  color: #2dcec6;
}

.linksRouter p b:hover {
  color: #6c757d;
}
</style>
