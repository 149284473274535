<template>
  <ListadoGeneral
    class="cards"
    :cargando="cargando"
    :msg-error="MsgError"
    :info="info"
    titulo="Encuestas"
    tipo="encuesta"
    tipo-icono="fas fa-file-signature"
    parrafo="En esta sección podrás adaptar preguntas con tus preferencias, para que tus clientes cuenten su experiencia."
    :base-gets-listado="BaseGetsListado"
    @actualiza="Actualiza"
    @agregar="Agregar"
    @editar="Editar"
    @borrar="Borrar"
    @reactivar="Reactivar"
  >
  </ListadoGeneral>
</template>

<script>
import ListadoGeneral from "@/Componentes/ListadoGeneral.vue";
import Servicio from "@/api-services/encuestas.service";
export default {
  name: "EncuestasListado",
  components: {

    ListadoGeneral,
  },
  data() {
    return {
      info: null,
      //Muestra el cargando
      cargando: true,
      //Indica que hay error
      MsgError: "",
      BaseGetsListado: {
        Borrados: false,
        PaginaNo: 0,
        PaginaLen: 10,
        Filtro: "",
      },
    };
  },
  methods: {
    Agregar(Parametros) {
      Servicio.post(
        this,
        Parametros.registro,
        (response) => {
          if (Parametros.OnAgregado != null) Parametros.OnAgregado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
    Evento de borrar registro
    @param Parametros{
      registro: registro a borrar
      OnBorrado: callback para ejecutar después de borrar
    }
  */
    Borrar(Parametros) {
      Servicio.delete(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    /*
    Evento de Reactivar registro
    @param Parametros{
      registro: registro a borrar
      OnBorrado: callback para ejecutar después de borrar
    }
  */
    Reactivar(Parametros) {
      Servicio.patch(
        this,
        Parametros.registro.id,
        (response) => {
          if (Parametros.OnBorrado != null) Parametros.OnBorrado(response);
          //    console.log("info", this.info);
        },
        (MsgError, Error) => {
          if (Parametros.OnError != null) Parametros.OnError(MsgError, Error);
        }
      );
    },
    Actualiza(value) {
      this.BaseGetsListado = value;
      Servicio.gets(this, value, (response) => {
        this.info = response.data;
        //    console.log("info", this.info);
      });
    },
  },
};
</script>

<style lang="css" scoped>
</style>