<!-- @format -->

<template>
  <div class="nav-item dropdown d-flex align-items-center" :class="isRTL ? 'ps-2' : 'pe-2'">
    <a
      id="dropdownMenuButton"
      href="#"
      :class="`p-0 nav-link ${
        isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
      } ${showMenu ? 'show' : ''}`"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="showMenu = !showMenu"
    >
      <MazAvatar no-clickable-icon :src="urlFotoMiniatura" tabindex="-1" />
    </a>
    <ul
      class="px-2 py-3 dropdown-menu dropdown-menu-end "
      :class="showMenu ? 'show' : ''"
      aria-labelledby="dropdownMenuButton"
    >
      
      <navbar-drop-down-item>
        <i class="far fa-user"></i>
               Mi Perfil
      </navbar-drop-down-item>
      
     
      <li><hr class="dropdown-divider"></li>
      <navbar-drop-down-item>
        <i class="fa fa-clock me-2"></i>
                1 day
      </navbar-drop-down-item>

    </ul>
  </div>
</template>

<script>
import MazAvatar from "maz-ui/components/MazAvatar";
import NavbarDropDownItem from "./NavbarDropDownItem.vue";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    components: {
        MazAvatar,
        NavbarDropDownItem
    },
    props: {
        urlFotoMiniatura: {
            type: String,
            required: true
        }
    },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
 
 
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),

  },
};
</script>
