<!-- @format -->
<template>
  <div id="navbar-personal-edicion" class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item">
        <a class="nav-link active mb-0 px-0 py-1" href="#califica-colaborador" @click="cambiarValor('Calificaciones')">Calificaciones</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-1" href="#evalua-colaborador" @click="cambiarValor('Evaluaciones')">Evaluaciones</a>
      </li>
    </ul>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";


export default {
  name: "MenuCalificacionesColaboradores",
  components: {

  },
  props: {
    modelValue: {
      type: String,
      required: true,
      default: 'Calificaciones'
    },
  },
  data() {
    return {
      Seleccion: 'Calificaciones'

    }
  },
  mounted() {
    setNavPills();
  },

  methods: {
    cambiarValor(nuevoValor) {
      this.Seleccion = nuevoValor;
      // Emitir un evento para cambiar el valor
      this.$emit('update:modelValue', nuevoValor);
    }
  }
};
</script>