<!-- @format -->

<template>
  <MazInput v-model="Curp" type="text" :label="label" :error="failed" :success="passed" @update:model-value="Actualiza">
    <template #right-icon>
      <div v-if="passed" class="text-success">
        <i class="fas fa-check p-2"></i>
      </div>
      <div v-else-if="failed" class="text-danger">
        <i class="fas fa-times p-2"></i>
      </div>
    </template>
  </MazInput>
</template>

<script>
export default {
  name: "CURPInput",
  // Propiedades del componente aquí
  props: {
    modelValue: { type: String, default: null },
    label: {
      type: String,
      required: false,
      default: "CURP",
    },
    // Solo valida si es Mex o '' o  null
    nacionalidad: { type: String, default: "MEX" },
  },
  emits: ["Actualiza"],
  // Datos del componente aquí
  data() {
    return {
      Curp: this.modelValue == null ? "" : this.modelValue,
    };
  },
  computed: {
    passed() {
      return this.curpValida(this.Curp);
    },
    failed() {
      if (this.Curp == null || this.Curp == "") return false;
      return !this.curpValida(this.Curp);
    },
  },
  watch: {
    modelValue(valor) {
      //console.log(`value=${valor}`);

      this.Curp = valor;
      return;
    },
  },
  // Métodos del componente aquí
  methods: {
    filterNonAphaNumeric(str) {
      let code,
        i,
        len,
        result = "";

      for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (
          (code > 47 && code < 58) || // numeric (0-9)
          (code > 64 && code < 91) || // upper alpha (A-Z)
          (code > 96 && code < 123)
        ) {
          // lower alpha (a-z)
          result += str.charAt(i);
        }
      }
      return result;
    },
    digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (var i = 0; i < 17; i++) lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito == 10) return 0;
      return lngDigito;
    },
    //Función para validar una CURP
    curpValida(valor) {
      var curp = valor;
      if (valor == null) curp = "";

      var re =
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = curp.match(re);

      if (!validado)
        //Coincide con el formato general?
        return false;

      //Validar que coincida el dígito verificador

      if (validado[2] != this.digitoVerificador(validado[1])) return false;

      return true; //Validado
    },
    Actualiza(valor) {
      var NValor = this.filterNonAphaNumeric(valor).toUpperCase();
      // console.log(NValor);
      this.$nextTick(() => {
        this.Curp = NValor;
        this.$emit("Actualiza", NValor);
      });
      /*  this.Curp = valor;
      console.log(valor);*/
    },
  },
  // Ciclo de vida del componente aquí
  created() {},
  mounted() {},
  // Otros hooks del ciclo de vida aquí
};
</script>

<style scoped>
/* Estilos del componente aquí */
</style>
