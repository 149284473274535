<template>
  <div class="card p-3">
    <button type="button" class="position-absolute top-0 end-0 p-3">
      <i class="fas fa-times text-primary"></i>
    </button>
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col">
            <MazInput
              v-model="inputValue"
              label="Texto de la pregunta"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <MazCheckbox v-model="checked">Pregunta obligatoria</MazCheckbox>
          </div>
          <div class="col-6">
            <MazCheckbox v-model="checked"
              >Contará en la puntuación de tu colaborador
            </MazCheckbox>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col">
            <p class="text-primary text-bold">Tipo de calificación</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioTipoRango"
              />
              <label class="form-check-label" for="flexRadioTipoRango">
                De rango
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioTipoRangoCerrada"
              />
              <label class="form-check-label" for="flexRadioTipoRangoCerrada">
                Cerrada (Si o No)
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioTipoRangoAbierta"
              />
              <label class="form-check-label" for="flexRadioTipoRangoAbierta">
                Abierta
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioTipoRangoOpciones"
              />
              <label class="form-check-label" for="flexRadioTipoRangoOpciones">
                Opciones
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <p class="text-primary text-bold">¿Qué icono te gustaría utilizar?</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioStar"
              />
              <label class="form-check-label" for="flexRadioStar">
                <i class="fas fa-star"></i>
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioSmile"
              />
              <label class="form-check-label" for="flexRadioSmile">
                <i class="fas fa-smile"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <p class="text-primary">Rango máximo</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDos"
              />
              <label class="form-check-label" for="flexRadioDos">
                2
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioTres"
              />
              <label class="form-check-label" for="flexRadioTres">
                3
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioCuatro"
              />
              <label class="form-check-label" for="flexRadioCuatro">
                4
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioCinco"
              />
              <label class="form-check-label" for="flexRadioCinco">
                5
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {},
  data() {
    return {};
  },
};
</script>
  
  <style lang="scss" scoped>
</style>