<template>
  <div>
    <h3>{{ title }}</h3>
    <canvas ref="lineChart"></canvas>
  </div>
</template>
  
  <script>
// import { Line, mixins } from 'vue-chartjs';
import Chart from "chart.js/auto";

export default {
  data() {
    return {
      chartId: "chart-line",
    };
  },
  mounted() {
    var lineChart = document.getElementById(this.chartId).getContext("2d");
    var gradientStroke1 = lineChart.createLinearGradient(0, 230, 0, 50);

    var gradientStroke2 = lineChart.createLinearGradient(0, 230, 0, 50);

    gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
    gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
    gradientStroke2.addColorStop(0, "rgba(20,23,39,0)"); //purple colors
    // Line chart

    let chartStatus = Chart.getChart(this.id);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    new Chart(lineChart, {
      type: "line",
      data: {
        labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Facebook Ads",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 4,
            pointBackgroundColor: "#2DCEC6 ",
            borderColor: "#2DCEC6",
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            backgroundColor: gradientStroke1,
            fill: true,
            data: [0, 100, 450, 400, 700, 430, 600, 450],
            maxBarThickness: 6,
          },
          /* {
              label: "Google Ads",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 2,
              pointBackgroundColor: "#3A416F",
              borderColor: "#3A416F",
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              backgroundColor: gradientStroke2,
              fill: true,
              data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
              maxBarThickness: 6,
            }, */
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#9ca2b7",
              padding: 10,
            },
          },
        },
      },
    });
  },
};
</script>