const paises = [
  "AF|AFG|Afganistán|afgano",
  "AL|ALB|Albania|albanés",
  "DE|DEU|Alemania|alemán",
  "AD|AND|Andorra|andorrano",
  "AO|AGO|Angola|angoleño",
  "AI|AIA|Anguila|anguilense",
  "AQ|ATA|Antártida|antártico",
  "AG|ATG|Antigua y Barbuda|antiguano",
  "SA|SAU|Arabia Saudita|saudí",
  "DZ|DZA|Argelia|argelino",
  "AR|ARG|Argentina|argentino",
  "AM|ARM|Armenia|armenio",
  "AW|ABW|Aruba|arubeño",
  "AU|AUS|Australia|australiano",
  "AT|AUT|Austria|austriaco",
  "AZ|AZE|Azerbaiyán|azerbaiyano",
  "BE|BEL|Bélgica|belga",
  "BS|BHS|Bahamas|bahameño",
  "BH|BHR|Bahrein|bareiní",
  "BD|BGD|Bangladesh|bangladesí",
  "BB|BRB|Barbados|barbadense",
  "BZ|BLZ|Belice|beliceño",
  "BJ|BEN|Benín|beninés",
  "BT|BTN|Bhután|butanés",
  "BY|BLR|Bielorrusia|bielorruso",
  "MM|MMR|Birmania|birmano",
  "BO|BOL|Bolivia|boliviano",
  "BA|BIH|Bosnia y Herzegovina|bosnio",
  "BW|BWA|Botsuana|botsuano",
  "BR|BRA|Brasil|brasileño",
  "BN|BRN|Brunéi|bruneano",
  "BG|BGR|Bulgaria|búlgaro",
  "BF|BFA|Burkina Faso|burkinés",
  "BI|BDI|Burundi|burundés",
  "CV|CPV|Cabo Verde|caboverdiano",
  "KH|KHM|Camboya|camboyano",
  "CM|CMR|Camerún|camerunés",
  "CA|CAN|Canadá|canadiense",
  "TD|TCD|Chad|chadiano",
  "CL|CHL|Chile|chileno",
  "CN|CHN|China|chino",
  "CY|CYP|Chipre|chipriota",
  "VA|VAT|Ciudad del Vaticano|vaticano",
  "CO|COL|Colombia|colombiano",
  "KM|COM|Comoras|comorense",
  "CG|COG|República del Congo|congoleño",
  "CD|COD|República Democrática del Congo|congoleño",
  "KP|PRK|Corea del Norte|norcoreano",
  "KR|KOR|Corea del Sur|surcoreano",
  "CI|CIV|Costa de Marfil|marfileño",
  "CR|CRI|Costa Rica|costarricense",
  "HR|HRV|Croacia|croata",
  "CU|CUB|Cuba|cubano",
  "CW|CWU|Curazao|curazoleño",
  "DK|DNK|Dinamarca|danés",
  "DM|DMA|Dominica|dominiqués",
  "EC|ECU|Ecuador|ecuatoriano",
  "EG|EGY|Egipto|egipcio",
  "SV|SLV|El Salvador|salvadoreño",
  "AE|ARE|Emiratos Árabes Unidos|emiratí",
  "ER|ERI|Eritrea|eritreo",
  "SK|SVK|Eslovaquia|eslovaco",
  "SI|SVN|Eslovenia|esloveno",
  "ES|ESP|España|español",
  "US|USA|Estados Unidos de América|estadounidense",
  "EE|EST|Estonia|estonio",
  "ET|ETH|Etiopía|etíope",
  "PH|PHL|Filipinas|filipino",
  "FI|FIN|Finlandia|finlandés",
  "FJ|FJI|Fiyi|fijiano",
  "FR|FRA|Francia|francés",
  "GA|GAB|Gabón|gabonés",
  "GM|GMB|Gambia|gambiano",
  "GE|GEO|Georgia|georgiano",
  "GH|GHA|Ghana|ghanés",
  "GI|GIB|Gibraltar|gibraltareño",
  "GD|GRD|Granada|granadino",
  "GR|GRC|Grecia|griego",
  "GL|GRL|Groenlandia|groenlandés",
  "GP|GLP|Guadalupe|guadalupeño",
  "GU|GUM|Guam|guameño",
  "GT|GTM|Guatemala|guatemalteco",
  "GF|GUF|Guayana Francesa|guayanés francés",
  "GG|GGY|Guernsey|guernesiano",
  "GN|GIN|Guinea|guineano",
  "GQ|GNQ|Guinea Ecuatorial|ecuatoguineano",
  "GW|GNB|Guinea-Bissau|guineano-bisau",
  "GY|GUY|Guyana|guyanés",
  "HT|HTI|Haití|haitiano",
  "HN|HND|Honduras|hondureño",
  "HK|HKG|Hong Kong|hongkonés",
  "HU|HUN|Hungría|húngaro",
  "IN|IND|India|indio",
  "ID|IDN|Indonesia|indonesio",
  "IR|IRN|Irán|iraní",
  "IQ|IRQ|Irak|iraquí",
  "IE|IRL|Irlanda|irlandés",
  "BV|BVT|Isla Bouvet",
  "IM|IMN|Isla de Man",
  "CX|CXR|Isla de Navidad",
  "NF|NFK|Isla Norfolk",
  "IS|ISL|Islandia|islandés",
  "BM|BMU|Islas Bermudas",
  "KY|CYM|Islas Caimán",
  "CC|CCK|Islas Cocos (Keeling)",
  "CK|COK|Islas Cook",
  "AX|ALA|Islas de Åland",
  "FO|FRO|Islas Feroe",
  "GS|SGS|Islas Georgias del Sur y Sandwich del Sur",
  "HM|HMD|Islas Heard y McDonald",
  "MV|MDV|Islas Maldivas",
  "FK|FLK|Islas Malvinas",
  "MP|MNP|Islas Marianas del Norte",
  "MH|MHL|Islas Marshall",
  "PN|PCN|Islas Pitcairn",
  "SB|SLB|Islas Salomón",
  "TC|TCA|Islas Turcas y Caicos",
  "UM|UMI|Islas Ultramarinas Menores de Estados Unidos",
  "VG|VGB|Islas Vírgenes Británicas",
  "VI|VIR|Islas Vírgenes de los Estados Unidos",
  "IL|ISR|Israel|israelí",
  "IT|ITA|Italia|italiano",
  "JM|JAM|Jamaica|jamaicano",
  "JP|JPN|Japón|japonés",
  "JE|JEY|Jersey|jerseyés",
  "JO|JOR|Jordania|jordano",
  "KZ|KAZ|Kazajistán|kazajo",
  "KE|KEN|Kenia|keniano",
  "KG|KGZ|Kirguistán|kirguiso",
  "KI|KIR|Kiribati|kiribatiano",
  "KW|KWT|Kuwait|kuwaití",
  "LB|LBN|Líbano|libanés",
  "LA|LAO|Laos|laosiano",
  "LS|LSO|Lesoto|lesotense",
  "LV|LVA|Letonia|letón",
  "LR|LBR|Liberia|liberiano",
  "LY|LBY|Libia|libio",
  "LI|LIE|Liechtenstein|liechtensteiniano",
  "LT|LTU|Lituania|lituano",
  "LU|LUX|Luxemburgo|luxemburgués",
  "MX|MEX|México|mexicano",
  "MC|MCO|Mónaco|monegasco",
  "MO|MAC|Macao|macaense",
  "MK|MKD|Macedonia|macedonio",
  "MG|MDG|Madagascar|malgache",
  "MY|MYS|Malasia|malasio",
  "MW|MWI|Malawi|malauí",
  "ML|MLI|Mali|maliense",
  "MT|MLT|Malta|maltés",
  "MA|MAR|Marruecos|marroquí",
  "MQ|MTQ|Martinica|martiniqués",
  "MU|MUS|Mauricio|mauriciano",
  "MR|MRT|Mauritania|mauritano",
  "YT|MYT|Mayotte|mahorés",
  "FM|FSM|Micronesia|micronesio",
  "MD|MDA|Moldavia|moldavo",
  "MN|MNG|Mongolia|mongol",
  "ME|MNE|Montenegro|montenegrino",
  "MS|MSR|Montserrat|montserratense",
  "MZ|MOZ|Mozambique|mozambiqueño",
  "NA|NAM|Namibia|namibio",
  "NR|NRU|Nauru|nauruano",
  "NP|NPL|Nepal|nepalí",
  "NI|NIC|Nicaragua|nicaragüense",
  "NE|NER|Niger|nigerino",
  "NG|NGA|Nigeria|nigeriano",
  "NU|NIU|Niue|niueano",
  "NO|NOR|Noruega|noruego",
  "NC|NCL|Nueva Caledonia|neocaledonio",
  "NZ|NZL|Nueva Zelanda|neozelandés",
  "OM|OMN|Omán|omaní",
  "NL|NLD|Países Bajos|neerlandés",
  "PK|PAK|Pakistán|paquistaní",
  "PW|PLW|Palau|palauano",
  "PS|PSE|Palestina|palestino",
  "PA|PAN|Panamá|panameño",
  "PG|PNG|Papúa Nueva Guinea|papú",
  "PY|PRY|Paraguay|paraguayo",
  "PE|PER|Perú|peruano",
  "PF|PYF|Polinesia Francesa|polinesio francés",
  "PL|POL|Polonia|polaco",
  "PT|PRT|Portugal|portugués",
  "PR|PRI|Puerto Rico|puertorriqueño",
  "QA|QAT|Qatar|qatarí",
  "GB|GBR|Reino Unido|británico",
  "CF|CAF|República Centroafricana|centroafricano",
  "CZ|CZE|República Checa|checo",
  "DO|DOM|República Dominicana|dominicano",
  "SS|SSD|República de Sudán del Sur|sursudanés",
  "RE|REU|Reunión|reunionés",
  "RW|RWA|Ruanda|ruandés",
  "RO|ROU|Rumanía|rumano",
  "RU|RUS|Rusia|ruso",
  "EH|ESH|Sahara Occidental|saharaui",
  "WS|WSM|Samoa|samoano",
  "AS|ASM|Samoa Americana|samoano americano",
  "BL|BLM|San Bartolomé|bartolomeño",
  "KN|KNA|San Cristóbal y Nieves|kittsiano o nevisiano",
  "SM|SMR|San Marino|sanmarinense",
  "MF|MAF|San Martín (Francia)|sanmartinense",
  "PM|SPM|San Pedro y Miquelón|sanpedrino",
  "VC|VCT|San Vicente y las Granadinas|vincenciano",
  "SH|SHN|Santa Elena|santaelenense",
  "LC|LCA|Santa Lucía|santalucense",
  "ST|STP|Santo Tomé y Príncipe|santotomense",
  "SN|SEN|Senegal|senegalés",
  "RS|SRB|Serbia|serbio",
  "SC|SYC|Seychelles|seychellense",
  "SL|SLE|Sierra Leona|sierraleonés",
  "SG|SGP|Singapur|singapurense",
  "SX|SMX|Sint Maarten|sintmaartense",
  "SY|SYR|Siria|sirio",
  "SO|SOM|Somalia|somalí",
  "LK|LKA|Sri Lanka|ceilandés",
  "ZA|ZAF|Sudáfrica|sudafricano",
  "SD|SDN|Sudán|sudanés",
  "SE|SWE|Suecia|sueco",
  "CH|CHE|Suiza|suizo",
  "SR|SUR|Surinám|surinamés",
  "SJ|SJM|Svalbard y Jan Mayen|svalbardense",
  "SZ|SWZ|Swazilandia|suazi",
  "TJ|TJK|Tayikistán|tayiko",
  "TH|THA|Tailandia|tailandés",
  "TW|TWN|Taiwán|taiwanés",
  "TZ|TZA|Tanzania|tanzano",
  "IO|IOT|Territorio Británico del Océano Índico|británico",
  "TF|ATF|Territorios Australes y Antárticas Franceses|francés",
  "TL|TLS|Timor Oriental|timorense",
  "TG|TGO|Togo|togolés",
  "TK|TKL|Tokelau|tokelauano",
  "TO|TON|Tonga|tongano",
  "TT|TTO|Trinidad y Tobago|trinitense",
  "TN|TUN|Tunez|tunecino",
  "TM|TKM|Turkmenistán|turcomano",
  "TR|TUR|Turquía|turco",
  "TV|TUV|Tuvalu|tuvaluano",
  "UA|UKR|Ucrania|ucraniano",
  "UG|UGA|Uganda|ugandés",
  "UY|URY|Uruguay|uruguayo",
  "UZ|UZB|Uzbekistán|uzbeko",
  "VU|VUT|Vanuatu|vanuatuense",
  "VE|VEN|Venezuela|venezolano",
  "VN|VNM|Vietnam|vietnamita",
  "WF|WLF|Wallis y Futuna|wallisiano o futuniano",
  "YE|YEM|Yemen|yemení",
  "DJ|DJI|Yibuti|yibutiano",
  "ZM|ZMB|Zambia|zambiano",
  "ZW|ZWE|Zimbabue|zimbabuense",
];
/*
export const countries = paises.map((country) => {
  var state_arr = country.split("|");
  return { iso2: state_arr[0], iso3: state_arr[1], nombre: state_arr[2]};
});*/
// Función para obtener el emoji de la bandera a partir del código ISO 2 del país
function flagEmoji(countryCode) {
  const codePoints = countryCode.toUpperCase().split('').map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export const countries = paises.map((country) => {
  var state_arr = country.split("|");
  var flag = flagEmoji(state_arr[0]); // Obtiene el emoji de la bandera
  var nacionalidad =state_arr.length>3?  state_arr[3]:state_arr[2];
   
  return { 
    iso2: state_arr[0], 
    iso3: state_arr[1], 
    nombre: state_arr[2],
    nacionalidad: nacionalidad,
    etiqueta: `${flag} ${state_arr[2]}`, 
    etiquetaNacionalidad: `${flag} ${nacionalidad}`  
  };
});
export const countriesIso = countries.map(country => country.iso2.toUpperCase());


