<template>
  <div
    class="p-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="row d-flex justify-content-between">
        <div class="col-auto">
          <div v-if="!MuestraEdit">
            <h5>Elige la forma de entrega</h5>
            <CardDomicilio class="mb-3" @EditarDom="Editar" />
            <CardPuntoEntrega class="mb-3" />
            <div class="d-grid gap-2">
              <p class="text-center">Con los datos obtenidos crearemos tu gafete</p>
              <button
                class="btn btn-lg btn-primary"
                type="button"
                @click="emit('nextStep')"
              >
                Solicitar ahora
              </button>
            </div>
          </div>
          <div v-else>
            <!-- Aqui editamos
            <h4>Ubicación de envío</h4> -->
            <UbicacionEnvio />
          </div>
        </div>
        <div class="col-auto">
          <div v-if="!MuestraEdit">
            <div class="fondo" :class="{ flipped: showBack }">
              <div class="card-inner">
                <div class="front">
                  <img
                    style="margin-top: 11rem; width: 14rem"
                    class="img-fluid"
                    src="@/assets/img/logosya/Gafetes-22.png"
                    alt="profile card"
                  />
                  <p style="margin-top: 2rem">
                    {{ NombreColaborador }}
                  </p>
                  <p style="margin-top: 2rem">
                    {{ Puesto }}
                  </p>
                </div>
                <div class="back">
                  <img
                    style="margin-top: 11rem; width: 15rem"
                    class="img-fluid"
                    src="@/assets/img/logosya/Gafetes-21.png"
                    alt="profile card"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button
                class="btn btn-success rounded-circle"
                type="button"
                @click="toggleCard"
              >
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
          <div v-else></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, onMounted } from "vue";
import Quill from "quill";
import Choices from "choices.js";
import CardDomicilio from "./CardDomicilio.vue";
import CardPuntoEntrega from "./CardPuntoEntrega.vue";
import Servicio from "@/api-services/colaboradores.service";
import UbicacionEnvio from "../../../Componentes/UbicacionEnvio.vue";

const props = defineProps({
  registro: {
    type: Object,
    required: true,
  },
  Datos: {
    type: Object,
    default: () => ({ etiqueta: "" }),
  },
  fechaSolicitud: {
    type: String,
    default: "",
  },
  fechayHoraEntrega: {
    type: String,
    default: "",
  },
  recibio: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["nextStep"]);

const showFront = ref(true);
const showBack = ref(false);
const MuestraEdit = ref(false);
const FotoURLFront = "@/assets/img/logosya/Gafetes-22.png";
const FotoURLBack = "@/assets/img/logosya/Gafetes-21.png";
const NombreColaborador = ref("Nombre colaborador");
const Puesto = ref("Puesto");

const toggleCard = () => {
  showBack.value = !showBack.value;
};

const Editar = () => {
  MuestraEdit.value = true;
};

onMounted(() => {
  if (document.getElementById("edit-description")) {
    const quill = new Quill("#edit-description", {
      theme: "snow",
    });
  }
  if (document.getElementById("choices-category")) {
    const element = document.getElementById("choices-category");
    new Choices(element, {
      searchEnabled: false,
      allowHTML: true,
    });
  }
  if (document.getElementById("choices-sizes")) {
    const element = document.getElementById("choices-sizes");
    new Choices(element, {
      searchEnabled: false,
      allowHTML: true,
    });
  }
});
</script>

<style>
.fondo {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 320px;
  height: 691px;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  position: relative;
  transition: background-image 0.5s;
  perspective: 1000px;
}

.card-inner {
  width: 320px;
  height: 691px;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flipped .card-inner {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}

.fondo-front {
  background-image: url("@/assets/img/logosya/Gafetes-22.png");
  background-image: url("@/assets/img/logosya/Gafetes-221.png");
}

.fondo-back {
  background-image: url("@/assets/img/logosya/Gafetes-21.png");
  background-image: url("@/assets/img/logosya/Gafetes-211.png");
}

.front {
  position: absolute;
  /* Asegura que la imagen esté encima de la imagen de la parte trasera */
}

.btn {
  transition: transform 0.5s;
  /* Agrega una transición para el giro del botón */
}

.btn.rotated {
  transform: rotateY(180deg);
  /* Gira el botón 180 grados en el eje Y */
}
</style>